import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormInput } from '@shk/prepear-controls/controls/form';
import Ic from '@shk/prepear-controls/controls/icons';
import getUserAction from '../actions/get-user-action';
import refundPaymentAction from '../actions/refund-payment-action';
import cancelSubscriptionAction from '../actions/cancel-subscription-action';
import  updateSubscriptionAction from '../actions/update-subscription-action';

import Modal from '../../../components/common/Modal';
import {parseDateId} from '../../../lib/dateUtil';

import './CustomerInfoMain.css';
import { text } from '@shk/react-simple-intl';
import {
    CANCEL_AT_PERIOD_END,
    CANCEL_AT_TRIAL_END,
    CANCELED,
    STATE_OPTIONS,
    CANCELED_STATES,
    PENDING_STATES,
    ACTIVE_STATES,
    IOS_SUB_TYPES,
    ANDROID_SUB_TYPES,
    STRIPE_AND_PAYPAL_SUB_TYPES,
    isFullAccess
} from '../lib/subscriptionHelper';

const SIX_SISTERS_PARTNER_ID = '07fd0a40-cb99-11ea-8d9e-085f4f65f7fe';
export class CustomerInfoMain extends PureComponent {
    state = {
        subscription: {},
        editSub: {
            isNew: true,
            sub_id: 'select',
            billing_amt: '0',
            full_access_purchase_amt: 0,
            has_trialed: false,
            billing_interval: 1,
            billing_period: 'year',
            discount_id: '',
            full_access_partner_id: '07fd0a40-cb99-11ea-8d9e-085f4f65f7fe',
            long_term_referral_id: '',
            payment_gateway: 'stripe',
            payment_ids: '',
            plans: null,
            referral_partner_id: null,
            short_term_referral_id: null,
            sub_started: new Date(),
            expires: new Date(),
            state: 'IN_TRIAL'
        },
        paypalUrl: null,
        paymentToRefund: {},
        paypalRefundModalOpen: false,
        user: {},
        payments: [],
        openEditModal: false,
        discounts: [],
        cancelTime: 'immediate',
    }

    componentDidMount() {
        const {customerId} = this.props.match.params;
        this._getUserData(customerId);
    }

    _getUserData = async (customerId) =>  {
        const res = await this.props.getUser(customerId);

        const {user, subscription, payments, pros, discounts} = res.userData;
        var totalPaid = 0;

        if(subscription.referral_partner_id) {
            const refUser = await this.props.getUser(subscription.referral_partner_id);
            subscription.referrer = refUser.userData.user && refUser.userData.user.display_name;
        }

        if(subscription.full_access_partner_id) {
            const ownerUser = await this.props.getUser(subscription.full_access_partner_id);
            subscription.subOwner = ownerUser.userData.user.display_name;
        }

        if(subscription.sub_id) {
            const interval = subscription && subscription.billing_interval;
            const startDate = subscription && new Date(subscription.sub_started)

            subscription.started = startDate && (startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear());

            if(subscription && subscription.billing_period) {
                subscription.billingFrequency = subscription.billing_period === 'year' ? 'Billing Yearly:' : 'Billing Monthly:';
            } else {
                subscription.billingFrequency = 'No Subscription'
            }

            subscription.status = subscription && subscription.state ? subscription.state.replaceAll('_', ' ') : 'None';

            if(interval && interval !== 1) {
                subscription.billingFrequency = 'Billing every ' +  interval + ' Months';
            }
        }

        if(payments.length) {
            totalPaid = payments.reduce((prev, cur) => {
                return prev + (parseInt(cur.total) || 0 );
           }, 0)
        }

        discounts.push({ownerName: 'none', title: '', amount: null, id: ''})

        const editSub = {
            ...subscription,
            sub_id: subscription.sub_id ? subscription.sub_id : 'select',
            state: subscription.state || CANCELED,
            full_access_partner_id: subscription.full_access_partner_id || SIX_SISTERS_PARTNER_ID,
            billing_amt: subscription.billing_amt || '0'
        }

        this.setState({
            user,
            subscription,
            editSub: !subscription.sub_id && !subscription.payment_gateway ? {...this.state.editSub, user_id: user.id} : editSub,
            payments,
            totalPaid: (totalPaid / 100).toFixed(2),
            pros,
            discounts,
        })
    }

    _amountOptions = (editSub) => {
        var startAmt = 99;
        const amounts = [parseInt(editSub.billing_amt || 0)];
        for(var i = 0; i < 130; i++) {
            if(amounts.indexOf(startAmt) === -1) {
                amounts.push(startAmt);
            }
            startAmt += 100;
        }

        return amounts;
    }

    _onClickToggleEditSub = () => {
        const {openEditModal} = this.state;

        this.setState({
            openEditModal: !openEditModal,
        })
    }

    onEdit = (prop) => (event) => {
        const {editSub} = this.state;
        var value;

        if(prop === 'expires') {
            value = parseDateId(event.target.value);
        } else {
            value = event.target.value
        }

        this.setState({
            editSub: {
                ...editSub,
                [prop]: value,
            }
        })
    }

    _onTogglePaypalRefundModal = () => {
        this.setState({
            paypalUrl: null,
            paypalRefundModalOpen: false,
            paymentToRefund: {},
        });
    }

    _onClickRefund = (payment) => async () => {
        const {subscription} = this.state;
        var paypalUrl;

        if(subscription.payment_gateway === 'paypal') {
            var paypalId = subscription.payment_ids['paypal'];
            paypalId = paypalId.startsWith('SS') ? paypalId.slice(2) : paypalId;
            paypalUrl = `https://www.paypal.com/us/cgi-bin/webscr?cmd=%5fprofile%2dmerchant%2dpull&encrypted_profile_id=${encodeURIComponent(paypalId)}&mp_id=${encodeURIComponent(paypalId)}`
        }

        this.setState({
            paypalUrl,
            paymentToRefund: payment,
            paypalRefundModalOpen: true,
        });
    }

    _onConfirmRefund = async () => {
        const {user, paymentToRefund, subscription} = this.state;
        await this.props.refundPayment(paymentToRefund, user.id, subscription.payment_gateway);

        this.setState({
            paypalUrl: null,
            paymentToRefund: {},
            paypalRefundModalOpen: false,
        }, () => {
            this._getUserData(subscription.user_id);
        })
    }

    _onConfirmRefundPrepear = async () => {
        const {user, paymentToRefund, subscription} = this.state;
        await this.props.refundPayment(paymentToRefund, user.id, subscription.payment_gateway, true);

        this.setState({
            paypalUrl: null,
            paymentToRefund: {},
            paypalRefundModalOpen: false,
        }, () => {
            this._getUserData(subscription.user_id);
        })
    }

    _onToggleCancelSubModel = () => {
        const {toggleModal} = this.state;

        this.setState({
            toggleModal: !toggleModal,
            modalContent: {}
        })
    }

    _onClickSelectCancelTime = (time) => () => {
        this.setState({
            cancelTime: time
        })
    }

    _onClickCancelConfirm = () => {
        const {subscription, cancelTime} = this.state;
        const cancelNow = cancelTime === 'immediate';

        this.props.cancelSub(subscription.user_id, cancelNow)
        .then((res) => {
            if(!res.success) {
                alert('Canceled Subscription Fail')
            }
            this.setState({
                toggleModal: false,
                modalContent: {},
                openEditModal: false,
            }, () => {
                this._getUserData(subscription.user_id);
            })
        })
    }

    _updateSubscription = () => {
        const {editSub, user} = this.state;

        this.props.updateSubscription(editSub, user)
        .then((res)=> {
            if(res.success) {
                this.setState({
                    openEditModal: false,
                }, () => {
                    this._getUserData(editSub.user_id);
                })
            }
        })
    }


    _editSubForm = () => {
        const { editSub, pros, discounts, subscription} = this.state;
        const {payment_gateway} = subscription;

        const expireDate = editSub.expires ? new Date(editSub.expires) : null;
        const datePickerValue = expireDate ? expireDate.getFullYear() + '-' +  ('0' + (expireDate.getMonth() + 1)).slice(-2) + '-' + ('0' + expireDate.getDate()).slice(-2) : '';
        const amountOptions = this._amountOptions(editSub);
        const isAppStoreSubscription = payment_gateway && (payment_gateway === 'ios' || payment_gateway === 'android');
        var sub_id_types;

        if(payment_gateway === 'ios') {
            sub_id_types = IOS_SUB_TYPES;
        } else if (payment_gateway === 'android') {
            sub_id_types = ANDROID_SUB_TYPES;
        } else {
            sub_id_types = STRIPE_AND_PAYPAL_SUB_TYPES;
        }

        return (
           <div className="user-support-edit-sub-form">
                <FormInput
                    placeholder={text('CustomerInfoMain.type', 'Subscription Type')}
                    label={text('CustomerInfoMain.type', 'Subscription Type')}
                    value={editSub.sub_id}
                    onChange={this.onEdit('sub_id')}
                    type={'select'}
                    options={sub_id_types}
                />
                {isFullAccess(editSub.sub_id) && <FormInput
                    placeholder={text('CustomerInfoMain.proName', 'Full Access Pro')}
                    label={text('CustomerInfoMain.proName', 'Full Access Pro')}

                    value={editSub.full_access_partner_id && (editSub.full_access_partner_id.toString() || 'ec87c8f0-3118-11eb-bcf9-4385ac28ddf3')}
                    onChange={this.onEdit('full_access_partner_id')}
                    type={'select'}
                    options={Object.keys(pros).map(id => {
                        return {text: pros[id].displayName, value: pros[id].id}
                    })}
                />}
                <FormInput
                    placeholder={text('CustomerInfoMain.type', 'Subscription State')}
                    label={text('CustomerInfoMain.type', 'Subscription State')}
                    value={editSub.state}
                    onChange={this.onEdit('state')}
                    type={'select'}
                    options={STATE_OPTIONS}
                />
                <FormInput
                    placeholder={text('CustomerInfoMain.proName', 'Billing Amount')}
                    label={text('CustomerInfoMain.proName', 'Billing Amount')}
                    value={editSub.billing_amt}
                    onChange={this.onEdit('billing_amt')}
                    type={'select'}
                    options={amountOptions && amountOptions.map(amt => {
                        return {text: '$' + (amt / 100).toFixed(2), value: amt.toString()};
                    })}
                />
                <label >{text('CustomerInfoMain.type', 'Next Bill Date')}</label>
                <input
                    type="date"
                    className='user-support-date-picker'
                    value={datePickerValue}

                    onChange={this.onEdit('expires')}
                />
                {!isAppStoreSubscription && <FormInput
                    placeholder={text('CustomerInfoMain.proName', 'Discount on Next Bill')}
                    label={text('CustomerInfoMain.proName', 'Discount on Next Bill')}
                    value={editSub.discount_id ? editSub.discount_id : ''}
                    onChange={this.onEdit('discount_id')}
                    type={'select'}
                    options={discounts && discounts.map(discount => {
                        return discount.id ? {text: (discount.ownerName || 'Prepear') + ' / ' + discount.title + ' / ' + discount.amount * 100 + '%  OFF' , value: discount.id} : {text: 'No Discount', value: ''}
                    })}
                />}
                {!CANCELED_STATES.includes(subscription.state) && subscription.sub_id && <button onClick={this._onToggleCancelSubModel} className="button-secondary button-large">Cancel Subscription</button>}
                <button disabled={!editSub.expires} onClick={this._updateSubscription} className="button-primary button-large">{subscription.sub_id || subscription.payment_gateway ? 'Update' : 'Create'}</button>
            </div>
        )
    }

    showPayments = () => {
        const {subscription, payments} = this.state;

        return (
            <div>
                <br/>
                <h3>PAYMENTS</h3>
                <div>
                    <table className="user-support-payments-table">
                        <thead>
                            <tr>
                                <th>AMOUNT:</th>
                                <th>DESCRIPTION:</th>
                                <th>STATUS:</th>
                                <th>DATE:</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {payments && payments.map(payment => {
                            const {product_id, total, id, status, date} = payment;
                            const paymentTotal = (total / 100).toFixed(2);
                            const dateObj = parseDateId(date);
                            const today = new Date();
                            const canRefund =  Math.floor((today.getTime() - dateObj.getTime())) / (24*60*60*1000) < 45;

                            return <tr key={id}>
                                <td className="user-support-customer-payment-total">${paymentTotal}</td>
                                <td>{product_id}</td>
                                <td><div  className={status === "SUCCESS" ? "payment-success" : "payment-refunded"}>{status}</div></td>
                                <td>{text('CustomerInfoPage', '{date, date, month {short} day {numeric} year {numeric}}', {date: dateObj})}</td>
                                <td>{status === "SUCCESS" && canRefund && <button onClick={this._onClickRefund(payment)} className='user-support-sub-action-btn'>Refund</button>}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    confirmRefundModal = () => {
        const {paypalRefundModalOpen, paypalUrl, paymentToRefund, subscription} = this.state;
        const {sub_id, payment_gateway} = subscription;

        if(paypalUrl) {
            return (
                <div className="customer-info-cancel-modal">
                    <h3>Payment Made in Paypal</h3>
                    <div>{text('customerInfoMain.cancelSub', 'Refund the payment in paypal before refunding it here.')}</div>
                    <hr/>
                    <button  className="button-primary button-large"><a target="_blank" href={paypalUrl}>Go to Paypal</a></button>
                    <button onClick={this._onConfirmRefund} className="button-secondary button-large" >Refund in Prepear</button>
                </div>
            )
        } else if(payment_gateway === 'braintree') {
            return (
                <div className="customer-info-cancel-modal">
                    <h3>Payment Made in Braintree</h3>
                    <div>{text('customerInfoMain.cancelSub', 'For payments that were disputed in braintree only refund in Prepear.')}</div>
                    <hr/>
                    <button onClick={this._onConfirmRefund} className="button-primary button-large">Refund Braintree + Prepear</button>
                    <button onClick={this._onConfirmRefundPrepear} className="button-secondary button-large" >Refund in Prepear</button>
                </div>
            )
        } else  {
            return (
                <div className="customer-info-cancel-modal">
                    <h3>Are you sure you want to refund this payment?</h3>
                    <div>{text('customerInfoMain', '${amount}', {amount: paymentToRefund ? (paymentToRefund.total / 100).toFixed(2) : 0})}</div>
                    <div>{text('CustomerInfoPage', '{sub}', {sub: sub_id})}</div>
                    <div>{text('CustomerInfoPage', 'Payment Date: {date, date, month {short} day {numeric} year {numeric}}', {date: parseDateId(paymentToRefund.period_start)})}</div>

                    <hr/>
                    <button onClick={this._onConfirmRefund} className="button-primary button-large" >Confirm Refund</button>
                </div>
            )
        }


    }

    render() {
        const {user, subscription, payments, totalPaid, openEditModal, toggleModal, cancelTime, paypalRefundModalOpen, paypalUrl, paymentToRefund} = this.state;
        const {sub_id, started, payment_gateway, billingFrequency, status, expires, state, fullAccessPartnerName, payment_ids, discount, billing_amt, referrer, subOwner} = subscription;
        const billingAmt = billing_amt &&  (billing_amt / 100).toFixed(2)
        const {display_name} = user;
        var subscriptionClass;

        if(ACTIVE_STATES.includes(subscription.state)) {
            subscriptionClass = 'user-support-customer-sub-active';
        } else if(CANCELED_STATES.includes(subscription.state)) {
            subscriptionClass = 'user-support-customer-sub-cancel';
        } else if(PENDING_STATES.includes(subscription.state)) {
            subscriptionClass ='user-support-customer-sub-pending';
        }

        const hasSubscription = !!sub_id || !!payment_gateway;
        const stateText = STATE_OPTIONS.find(x => x.value === state);

        return (
            user ? <div className="user-support-customer-page">
                <header>
                    <h1>{user && user.email}</h1>
                </header>
                <div>
                    <div className="user-support-customer-info">
                        <h3>Account Details</h3>
                        <div className="user-support-bold-label">NAME</div>
                        <div>{display_name}</div>
                        <div className="user-support-bold-label">USER SINCE</div>
                        <div>{text('CustomerInfoPage', '{date, date, month {short} day {numeric} year {numeric}}', {date: parseDateId(user.sign_up)})}</div>
                        <div className="user-support-bold-label">CURRENT SUBSCRIPTION</div>
                        <div>{sub_id ? sub_id : 'No Subscription'}</div>
                        {fullAccessPartnerName  && <div>({fullAccessPartnerName})</div>}
                        <div className="user-support-bold-label">SUBSCRIPTION STATE</div>
                        <div>{stateText ? stateText.text : 'No Subscription'}</div>
                        {hasSubscription && <div className="user-support-bold-label">CURRENT DISCOUNT ON NEXT BILL</div>}
                        {hasSubscription && <div>{discount && discount.title ?  discount.title + ' - %' + discount.amount * 100 + ' OFF' : 'No Discount'}</div>}
                        <div className="user-support-bold-label">PAYMENTS TOTAL</div>
                        <div>${totalPaid}</div>
                        {hasSubscription && <div className="user-support-bold-label">SUBSCRIPTION STARTED</div>}
                        {hasSubscription && <div>{started}</div>}
                        {hasSubscription && <div className="user-support-bold-label">REFERRED BY</div>}
                        {hasSubscription && <div>{referrer}</div>}
                        {hasSubscription && <div className="user-support-bold-label">SUBSCRIPTION OWNER</div>}
                        {hasSubscription && <div>{subOwner}</div>}
                        {hasSubscription && <div className="user-support-bold-label">PAYMENT PROCESSOR</div>}
                        { payment_gateway && <div>{payment_gateway}</div>}
                        {hasSubscription && <div className="user-support-bold-label">PAYMENT PROCESSOR ID</div>}
                        {payment_ids && payment_gateway && <div>{ payment_ids[payment_gateway]}</div>}
                        { payment_ids && Object.keys(payment_ids).length > 1 && <div className="user-support-bold-label">OTHER PROCESSORT IDS</div>}
                        {payment_ids &&  Object.keys(payment_ids).map(gateway => {
                            if(gateway !== payment_gateway) {
                                return <div key={gateway}>{gateway + ' -- ' + payment_ids[gateway]}</div>
                            }
                        })}
                    </div>
                    {hasSubscription ? <div>
                        {!openEditModal ? <div className="user-support-customer-sub-payment-container">
                            <div className="user-support-customer-sub-conatainer">
                                <h3>SUBSCRIPTION</h3>
                                <table>
                                    <tbody>
                                        <tr className={subscriptionClass}>
                                            <td>
                                                <div className="user-support-sub-icon">
                                                    {CANCELED_STATES.includes(state) ? <Ic.CircleX/> : <Ic.SimpleCheck/>}
                                                </div>
                                                <div className='user-support-sub-title'>
                                                    <div>{sub_id}</div>
                                                    <div>{billingFrequency} ${billingAmt}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="user-support-sub-status">{status}</div>
                                            </td>
                                            <td>
                                                <div>{(subscription.state === CANCEL_AT_PERIOD_END || subscription.state === CANCEL_AT_TRIAL_END) ? 'CANCEL ON DATE:' : 'NEXT BILL DATE:'}</div>
                                                <div>{ expires ? text('CustomerInfoPage', '{date, date, month {short} day {numeric} year {numeric}}', {date: parseDateId(expires)}) : 'None'}</div>
                                            </td>
                                            <td>
                                            <button className='user-support-sub-action-btn' onClick={this._onClickToggleEditSub}>Edit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {this.showPayments()}
                        </div>
                        : <div className="user-support-customer-sub-payment-container user-support-edit-container">
                            <h3>EDIT SUBSCRIPTION</h3>
                            <div onClick={this._onClickToggleEditSub} className='user-support-close'>&#10005;</div>
                            <div>
                                {this._editSubForm()}
                            </div>
                        </div>}
                    </div>
                    :  <div>
                        {!openEditModal ? <div className="user-support-customer-sub-payment-container">
                            <div className="user-support-customer-sub-conatainer">
                                <h3>Subscriptions</h3>
                                <p>There are no active subscriptions</p>
                                <button onClick={this._onClickToggleEditSub} className="button-primary button-large">Create a Subscription</button>
                                {this.showPayments()}
                            </div>
                        </div>
                        : <div className="user-support-customer-sub-payment-container user-support-edit-container">
                            <h3>CREATE SUBSCRIPTION</h3>
                            <div onClick={this._onClickToggleEditSub} className='user-support-close'>&#10005;</div>
                            <div>
                                {this._editSubForm()}
                            </div>
                        </div>}
                    </div>}
                </div>
                <Modal Open={toggleModal} onClose={this._onToggleCancelSubModel}>
                    <div className="customer-info-cancel-modal">
                        <h3>Cancel Subscription</h3>
                        <div>{text('customerInfoMain.cancelSub', 'When do you want the subscription to end?')}</div>
                        <div>
                            <div onClick={this._onClickSelectCancelTime('immediate')} className={cancelTime === 'immediate' ? 'customer-cancel-btn-time selected' : 'customer-cancel-btn-time'}>
                                <div>{text('CustomerInfoMain.immediatly', 'Immediately')}</div>
                                <div>{text('CustomerInfoPage', '{date, date, month {short} day {numeric} year {numeric}}', {date: new Date()})}</div>
                            </div>
                            <div onClick={this._onClickSelectCancelTime('period-end')}  className={cancelTime === 'period-end' ? 'customer-cancel-btn-time selected' : 'customer-cancel-btn-time'}>
                                <div>{text('CustomerInfoMain.periodEnd', 'End of period:')}</div>
                                <div>{text('CustomerInfoPage', '{date, date, month {short} day {numeric} year {numeric}}', {date: parseDateId(subscription.expires)})}</div>
                            </div>
                        </div>
                        <hr/>
                        <button onClick={this._onToggleCancelSubModel} className="button-secondary button-large">Don't Cancel</button>
                        <button onClick={this._onClickCancelConfirm} className="button-danger button-large" >Cancel Subscription</button>
                    </div>
                </Modal>
                <Modal Open={paypalRefundModalOpen} onClose={this._onTogglePaypalRefundModal}>
                {this.confirmRefundModal()}
                </Modal>
            </div>
            : <div>No User Information</div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => {
            return dispatch(getUserAction(id));
        },
        refundPayment: (payment, customerId, paymentGateway,  refundInPrepearOnly) => {
            return dispatch(refundPaymentAction(payment, customerId, paymentGateway, refundInPrepearOnly))
        },
        cancelSub: (customerId, cancelNow) => {
            return dispatch(cancelSubscriptionAction(customerId, cancelNow))
        },
        updateSubscription: (subscription, user) => {
            return dispatch(updateSubscriptionAction(subscription, user))
        }
    };
}

export default connect(
    null,
    mapDispatchToProps
)(CustomerInfoMain);
