import axios from 'axios';

export const LOAD_INGREDIENT_REQUEST = 'LOAD_INGREDIENT_REQUEST';
export const LOAD_INGREDIENT_SUCCESS = 'LOAD_INGREDIENT_SUCCESS';
export const LOAD_INGREDIENT_FAILURE = 'LOAD_INGREDIENT_FAILURE';
export const LOAD_INGREDIENT_CLEAR = 'LOAD_INGREDIENT_CLEAR';

export const NUTRIENTID_CALORIES = 208;
export const NUTRIENTID_FAT = 204;
export const NUTRIENTID_SATURATEDFAT = 606;
export const NUTRIENTID_TRANSFAT = 605;
export const NUTRIENTID_CHOLESTEROL = 601;
export const NUTRIENTID_SODIUM = 307;
export const NUTRIENTID_CARBS = 205;
export const NUTRIENTID_FIBER = 291;
export const NUTRIENTID_SUGARS = 269;
export const NUTRIENTID_PROTEIN = 203;
export const NUTRIENTIDS = [NUTRIENTID_CALORIES,
    NUTRIENTID_FAT,
    NUTRIENTID_SATURATEDFAT,
    NUTRIENTID_TRANSFAT,
    NUTRIENTID_CHOLESTEROL,
    NUTRIENTID_SODIUM,
    NUTRIENTID_CARBS,
    NUTRIENTID_FIBER,
    NUTRIENTID_SUGARS,
    NUTRIENTID_PROTEIN];

function stringify(value, defaultValue) {
    return (value || defaultValue || '').toString()
}

function transformArr(arr, transform) {
    if(arr && arr.length) {
        return arr.map(transform);
    } else {
        return [];
    }
}

function transformIngredient({ ingredient }) {
    ingredient.name = stringify(ingredient.name);
    ingredient.category = stringify(ingredient.category);
    ingredient.allergies = ingredient.allergies || [];
    ingredient.classifications = ingredient.classifications || [];
    ingredient.seasons = ingredient.seasons || [];
    ingredient.sub_categories = ingredient.sub_categories || [];
    ingredient.nutrient_grams = stringify(ingredient.nutrient_grams, '100');
    ingredient.nutrients = ingredient.nutrients || {};
    ingredient.standard_amount = '1';
    ingredient.standard_grams = stringify(ingredient.standard_measurement && ingredient.standard_measurement.grams);
    ingredient.standard_measurement = stringify(ingredient.standard_measurement && ingredient.standard_measurement.name);

    ingredient.measurements = transformArr(ingredient.measurements, (item, idx) => ({
        $id: idx + 1,
        amount: '1',
        grams: (item.grams || '0').toString(),
        name: item.name || '',
    }));

    ingredient.akas = transformArr(ingredient.akas, (item, idx) => ({
        $id: idx + 1,
        value: item,
    }));

    ingredient.mis_spellings = transformArr(ingredient.mis_spellings, (item, idx) => ({
        $id: idx + 1,
        value: item,
    }));

    NUTRIENTIDS.forEach(id => ingredient.nutrients[id] = stringify(ingredient.nutrients[id]));

    return {
        ingredient
    };
}

export default (ingredientId) => (dispatch) => {
    dispatch({ type: LOAD_INGREDIENT_REQUEST });

    return axios('/api/v1/ingredients/' + ingredientId)
        .then(
            (res) => {
                return dispatch({
                    type: LOAD_INGREDIENT_SUCCESS,
                    data: transformIngredient(res.data),
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: LOAD_INGREDIENT_FAILURE,
                    err
                }));
            }
        )
}

export function unloadIngredientAction() {
    return { type: LOAD_INGREDIENT_CLEAR };
}
