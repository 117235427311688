import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';
import { Link } from 'react-router-dom';

import searchIngredientsAction from '../actions/search-ingredients-action';
import createIngredientAction from '../actions/create-ingredient-action';
import loadIngredientAction, { unloadIngredientAction } from '../actions/load-ingredient-action';
import saveIngredientAction from '../actions/save-ingredient-action';

import { FormInput } from '@shk/prepear-controls/controls/form';
import Ic from '@shk/prepear-controls/controls/icons';

import './IngredientSearch.css';

export class IngredientSearch extends PureComponent {
    state = {
        search: '',
        autoAliasWorking: false,
    }

    componentDidMount() {
        this.props.searchIngredients();
    }

    _onChangeSearch = (e) => {
        this.setState({
            search: e.target.value,
        }, () => {
            if(!this.pendingSearch) {
                this.pendingSearch = setTimeout(() => {
                    this.pendingSearch = null;
                    this.props.searchIngredients(this.state.search);
                }, 300);
            }
        })
    }

    _onClickAutoAlias = ingredientMeta => async () => {
        this.setState({ autoAliasWorking: true });

        const { quickAlias } = this.props;
        const { data: { ingredient } } = await this.props.loadIngredient(ingredientMeta.id);

        ingredient.akas.push({ $id: ingredient.akas.length + 1, value: quickAlias });

        await this.props.saveIngredient(ingredient);

        this.props.onIngredientUpdated();

        this.setState({ autoAliasWorking: false });
    }

    render() {
        const { ingredients, queueId, quickAlias } = this.props;
        const { search, autoAliasWorking } = this.state;

        return (
            <div className="ingredient-search">
                <h2>{text('IngredientSearch.title', 'Ingredients')}</h2>
                <div className="ingredient-search-form cf">
                    <FormInput
                        placeholder={text('IngredientSearch.searchPlaceholder', 'Search')}
                        value={search}
                        onChange={this._onChangeSearch}
                    />
                    <Link className="btn btn-primary" to={queueId ? `/ingredients/queue/${queueId}/NEW` : `/ingredients/NEW`}>{text('IngredientSearch.addNew', 'Add New')}</Link>
                    <Ic.Search />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Search Match</th>
                            <th>Connected Ingredients</th>
                            <th style={{width:200}} />
                        </tr>
                    </thead>
                    <tbody>
                        {ingredients.map(ingredient => (
                            <tr key={ingredient.name_id}>
                                <td>{ingredient.name}</td>
                                <td>
                                    {ingredient.ingredients.map(ingredient => (
                                        <Link key={ingredient.id} to={queueId ? `/ingredients/queue/${queueId}/${ingredient.id}` : `/ingredients/${ingredient.id}`}>
                                            {ingredient.name}
                                        </Link>
                                    ))}
                                </td>
                                <td style={{width:200, textAlign: 'center'}}>
                                    {!!quickAlias && ingredient.ingredients.length === 1 && (
                                        <button className="btn btn-secondary btn-small" disabled={autoAliasWorking} onClick={this._onClickAutoAlias(ingredient.ingredients[0])}>Quick Add Alias</button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.ingredient.loading,
        ingredients: state.ingredient.entities,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        searchIngredients: function(search) {
            return dispatch(searchIngredientsAction(search));
        },
        createIngredient: function() {
            return dispatch(createIngredientAction());
        },
        loadIngredient: function(ingredientId) {
            return dispatch(loadIngredientAction(ingredientId));
        },
        unloadIngredient: function() {
            return dispatch(unloadIngredientAction());
        },
        saveIngredient: function(ingredient) {
            return dispatch(saveIngredientAction(ingredient));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientSearch);
