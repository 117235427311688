import axios from 'axios';

export const TYPEAHEAD_INGREDIENTS_REQUEST = 'TYPEAHEAD_INGREDIENTS_REQUEST';
export const TYPEAHEAD_INGREDIENTS_SUCCESS = 'TYPEAHEAD_INGREDIENTS_SUCCESS';
export const TYPEAHEAD_INGREDIENTS_FAILURE = 'TYPEAHEAD_INGREDIENTS_FAILURE';

export default (search) => (dispatch) => {
    dispatch({ type: TYPEAHEAD_INGREDIENTS_REQUEST });

    return axios('/api/v1/ingredients', {
        params: {
            q: search
        }
    })
        .then(
            (res) => {
                return dispatch({
                    type: TYPEAHEAD_INGREDIENTS_SUCCESS,
                    data: res.data,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: TYPEAHEAD_INGREDIENTS_FAILURE,
                    err
                }));
            }
        )
}
