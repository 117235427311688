import axios from 'axios';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export default (customerId, cancelNow) => (dispatch) => {

    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });

    return axios.post(`/api/v1/orchard/user-support/cancel-subscription`, {
        customerId,
        cancelNow
    })
        .then((res) => dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS, success: res.data.success,}))
        .catch(err => Promise.reject(dispatch({ type: CANCEL_SUBSCRIPTION_FAILURE })));
}
