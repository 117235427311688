import axios from 'axios';

export const RESOLVE_INGRED_ITEM_REQUEST = 'RESOLVE_INGRED_ITEM_REQUEST';
export const RESOLVE_INGRED_ITEM_SUCCESS = 'RESOLVE_INGRED_ITEM_SUCCESS';
export const RESOLVE_INGRED_ITEM_FAILURE = 'RESOLVE_INGRED_ITEM_FAILURE';

export default (queueId, queueItemId, ingredient) => (dispatch) => {
    dispatch({ type: RESOLVE_INGRED_ITEM_REQUEST });

    return axios.post(`/api/v1/orchard/queue/${queueId}/${queueItemId}`, {
        ingredient: ingredient && {
            name: (ingredient.name || '').trim(),
            amount: (ingredient.amount || '').trim(),
            id: ingredient.id,
            measurement: ingredient.measurement,
            note: ingredient.note && ingredient.note.trim(),
        }
    })
        .then(
            () => {
                return dispatch({
                    type: RESOLVE_INGRED_ITEM_SUCCESS,
                    queueId,
                    queueItemId,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: RESOLVE_INGRED_ITEM_FAILURE,
                    err
                }));
            }
        )
}
