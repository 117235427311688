import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import loadIngredientAction, { unloadIngredientAction } from './actions/load-ingredient-action';
import loadCurrentQueueItemsAction from './actions/load-current-queue-items-action';
import loadNextQueueItemsAction from './actions/load-next-queue-items-action';
import resolveQueueItem from './actions/resolve-queue-item-action';

// import IngredientQueueAuto from './components/IngredientQueueAuto';
// import IngredientQueueManual from './components/IngredientQueueManual';
// import IngredientSearch from './components/IngredientSearch';
import IngredientEdit from '../ingredients/components/IngredientEdit';

import './ShoppingMain.css';

export class ShoppingMain extends PureComponent {
    loadNextTries = 0

    state = {
        selectedItem: 0,
        queue: null,
    }

    componentWillMount() {
        this.props.unloadIngredient();
        this._selectQueue(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const currentParams = this.props.match.params;
        const nextParams = nextProps.match.params;
        const queueIdChanged = currentParams.queueId !== nextParams.queueId;

        if(queueIdChanged || this.props.queues !== nextProps.queues) {
            this._selectQueue(nextProps);
        }

        if(this.props.queueItems !== nextProps.queueItems) {
            this._loadItem(this.state.selectedItem, nextProps);
        }
    }

    _selectQueue({ match, queues, history }) {
        const { queueId } = match.params;

        if (queues.length) {
            if (!queueId && queues.length) {
                history.replace('/shopping/queue/' + queues[0].id);
            } else if (!this.state.queue || this.state.queue.id !== queueId) {
                this.props.loadCurrent(queueId);

                this.setState({
                    queue: queueId ? queues.find(x => x.id === queueId) : null
                });
            }
        }
    }

    _loadItem = (selectedItem, { queueItems, match: { params: { queueId } } }) => {
        if(queueItems[selectedItem]) {
            this.loadNextTries = 0;

            this.props.loadIngredient(queueItems[selectedItem].queue_item.id);
        } else if(this.loadNextTries < 10) {
            this.loadNextTries++;

            this.props.loadNext(queueId);
        }
    }

    _onIngredientUpdated = async (ingredient) => {
        if(ingredient.shopping_category) {
            const {queueItems, match: { params: { queueId } } } = this.props;
            const {selectedItem} = this.state;

            this.props.resolve(queueId, queueItems[selectedItem].id, ingredient);
        }
    }

    render() {
        const { ingredient } = this.props;

        return (
            <div className="shopping ingredients">
                {!!ingredient && <IngredientEdit
                    ingredientId={ingredient.id}
                    onIngredientUpdated={this._onIngredientUpdated}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        queues: state.queue.entities.shopping,
        ingredient: state.ingredient.details,
        ingredientLoading: state.ingredient.detailsLoading,
        queueItems: state.ingredientQueue.items,
        queueItemsLoading: state.ingredientQueue.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadCurrent: (queueId) => {
            dispatch(loadCurrentQueueItemsAction(queueId));
        },
        loadNext: (queueId) => {
            dispatch(loadNextQueueItemsAction(queueId));
        },
        loadIngredient: function(ingredientId) {
            return dispatch(loadIngredientAction(ingredientId));
        },
        unloadIngredient: function() {
            return dispatch(unloadIngredientAction());
        },
        resolve: (queueId, queueItemId, ingredient) => {
            return dispatch(resolveQueueItem(queueId, queueItemId, ingredient));
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingMain);
