import { LOAD_CURRENT_QUEUE_ITEMS_SUCCESS, LOAD_CURRENT_QUEUE_ITEMS_REQUEST } from '../actions/load-current-queue-items-action';
import { LOAD_NEXT_QUEUE_ITEMS_SUCCESS, LOAD_NEXT_QUEUE_ITEMS_REQUEST } from '../actions/load-next-queue-items-action';
import { RESOLVE_INGRED_ITEM_SUCCESS } from '../actions/resolve-queue-item-action';
import { ESCALATE_INGRED_ITEM_SUCCESS } from '../actions/escalate-queue-item-action';

const DEFAULT_STATE = {
    loading: false,
    recieved: false,
    items: [],
}

export default function ingredientQueueReducer(state = DEFAULT_STATE, action) {
    switch(action.type) {
        case LOAD_CURRENT_QUEUE_ITEMS_REQUEST:
        case LOAD_NEXT_QUEUE_ITEMS_REQUEST:
            return { ...state, loading: true };
        case LOAD_CURRENT_QUEUE_ITEMS_SUCCESS:
            return { ...state, loading: !action.data.hits.length, recieved: !!action.data.hits.length, items: action.data.hits };
        case LOAD_NEXT_QUEUE_ITEMS_SUCCESS:
            return { ...state, loading: false, recieved: true, items: state.items.concat([action.data]) };
        case RESOLVE_INGRED_ITEM_SUCCESS:
        case ESCALATE_INGRED_ITEM_SUCCESS:
            return { ...state, items: state.items.filter(item => item.id !== action.queueItemId) };
        default:
            return state;
    }
}
