import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Ic from '@shk/prepear-controls/controls/icons';
import getUserIdAction from './actions/get-user-id-action';
import './UserSupportMain.css';

export class UserSupportMain extends PureComponent {
    state = {
        userEmail: '',
    }

    _onChangeSearchValue = (event) => {
        this.setState({
            userEmail: event.target.value
        })
    }

    _onSubmitGetUser = async (input) => {
        if(input.which === 13) {
            this.setState({
                notFound: false,
            })
            const {userEmail} = this.state;
            const res = await this.props.getUserId(userEmail.toLowerCase().trim())
            if(res.userId) {
                this.props.history.push(`/user-support/customer/${res.userId}`)
            } else {
                this.setState({
                    notFound: true,
                })
            }
        }
    }
    render() {
        const {userEmail, notFound} = this.state;

        return (
            <div className="user-support">
                <div className="user-support-search-container">
                    <h1>Customer Lookup</h1>
                    {notFound && <div>No User with that Email</div>}
                    <div className="user-support-search-input-container">
                        <Ic.Search />
                        <input
                            placeholder={'Customer Email'}
                            type='input'
                            value={userEmail}
                            onChange={this._onChangeSearchValue}
                            onKeyUp={this._onSubmitGetUser}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUserId: (email) => {
            return dispatch(getUserIdAction(email));
        },
    };
}

export default connect(
    null,
    mapDispatchToProps
)(UserSupportMain);


