import { LOAD_USER_SUCCESS, LOAD_USER_FAILURE } from "../actions/load-user-action";

const DEFAULT_STATE = {
    loading: false,
    recieved: false,
    entity: null,
};

export default function ingredientReducer(state = DEFAULT_STATE, action) {
    switch(action.type) {
        case LOAD_USER_FAILURE:
            return { ...state, recieved: true };
        case LOAD_USER_SUCCESS:
            return { ...state, recieved: true, entity: action.data.user };
        default:
            return state;
    }
}
