import axios from 'axios';

export const SUB_GIFT_REQUEST = 'SUB_GIFT_REQUEST';
export const SUB_GIFT_SUCCESS = 'SUB_GIFT_SUCCESS';
export const SUB_GIFT_FAILURE = 'SUB_GIFT_FAILURE';

export default (email, trialDays) => (dispatch) => {
    dispatch({ type: SUB_GIFT_REQUEST });

    return axios.post('/api/v1/orchard/admin/gift-gold', {
        email,
        trialDays,
    })
        .then(() => dispatch({ type: SUB_GIFT_SUCCESS }))
        .catch(err => Promise.reject(dispatch({ type: SUB_GIFT_FAILURE })));
}
