import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';
import { FormInput } from '@shk/prepear-controls/controls/form';

import createProAction from '../actions/create-pro-action';

export class AdminCreatePro extends PureComponent {
    state = {
        publisherEmail: '',
        subscriberEmail: '',
        allowedHostname: '',
        working: false,
    }

    _onChangeState = (prop) => (e) => {
        this.setState({
            [prop]: e.target.value,
        });
    }

    _onClickGo = () => {
        this.setState({
            working: true,
        }, () => {
            const {
                publisherEmail,
                subscriberEmail,
                allowedHostname,
            } = this.state;

            this.props.createPro(publisherEmail, subscriberEmail, allowedHostname)
                .then(() => {
                    alert('DONE');
                });
        });
    }

    render() {
        const {
            publisherEmail,
            subscriberEmail,
            allowedHostname,
            working,
        } = this.state;

        return (
            <div className="admin" style={{width:480}}>
                <h2>Admin Create Pro</h2>
                <FormInput
                    label={text('AdminCreatePro.publisherEmail', 'Publisher Email')}
                    value={publisherEmail}
                    onChange={this._onChangeState('publisherEmail')}
                />
                <FormInput
                    label={text('AdminCreatePro.subscriberEmail', 'Test Subscriber Email')}
                    value={subscriberEmail}
                    onChange={this._onChangeState('subscriberEmail')}
                />
                <FormInput
                    label={text('AdminCreatePro.allowedHostname', 'Website Domain')}
                    value={allowedHostname}
                    onChange={this._onChangeState('allowedHostname')}
                />
                <div style={{textAlign:'right'}}>
                    <button type="button" className="btn btn-primary" disabled={working} onClick={this._onClickGo}>{text('AdminCreatePro.go', 'Apply')}</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createPro: (publisherEmail, subscriberEmail, allowedHostname) => {
            return dispatch(createProAction(publisherEmail, subscriberEmail, allowedHostname));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreatePro);
