import { SEARCH_INGREDIENTS_SUCCESS } from "../actions/search-ingredients-action";
import { LOAD_INGREDIENT_REQUEST, LOAD_INGREDIENT_SUCCESS, LOAD_INGREDIENT_CLEAR } from '../actions/load-ingredient-action';
import { CREATE_INGREDIENT_SUCCESS } from '../actions/create-ingredient-action';
import { SAVE_INGREDIENT_SUCCESS } from '../actions/save-ingredient-action';

const DEFAULT_STATE = {
    loading: false,
    recieved: false,
    entities: [],
    detailsLoading: false,
    details: null,
};

export default function ingredientReducer(state = DEFAULT_STATE, action) {
    switch(action.type) {
        case SEARCH_INGREDIENTS_SUCCESS:
            return { ...state, entities: action.data.ingredients };
        case LOAD_INGREDIENT_REQUEST:
            return { ...state, detailsLoading: true };
        case LOAD_INGREDIENT_SUCCESS:
            return { ...state, details: action.data.ingredient, detailsLoading: false };
        case LOAD_INGREDIENT_CLEAR:
            return { ...state, detailsLoading: false, details: null }
        case CREATE_INGREDIENT_SUCCESS:
        case SAVE_INGREDIENT_SUCCESS:
            return { ...state, details: action.ingredient, detailsLoading: false };
        default:
            return state;
    }
}
