export function parseDateId(dateId) {
    if(dateId) {
        if(typeof(dateId) !== 'string') {
            return new Date(dateId);
        } else {
            const match = dateId.match(/(\d{4})-(\d{1,2})-(\d{1,2})/);
            if(!match) {
                throw new Error('id was not in the right format "' + dateId + '"');
            }
            return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
        }
    }
    return null;
}
