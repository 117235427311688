import axios from 'axios';

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export default (customerId) => (dispatch) => {

    dispatch({ type: GET_CUSTOMER_REQUEST });

    return axios.get(`/api/v1/orchard/user-support/customer/${customerId}`)
        .then((res) => dispatch({ type: GET_CUSTOMER_SUCCESS, userData: res.data }))
        .catch(err => Promise.reject(dispatch({ type: GET_CUSTOMER_FAILURE })));
}
