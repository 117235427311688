import React, { PureComponent } from 'react';
import { text } from '@shk/react-simple-intl';

import { FormInput } from '@shk/prepear-controls/controls/form';
import Ic from '@shk/prepear-controls/controls/icons';

import './IngredientEditLanguage.css';
import 'flag-icon-css/css/flag-icon.css';

class IngredientEditLanguageGroup extends PureComponent {
    _onChange = (idx) => (e) => {
        const { onChange, items } = this.props;
        const newItems = items.slice();

        newItems[idx] = {
            $id: newItems[idx].$id,
            value: e.target.value
        };

        onChange(newItems);
    }

    _onClickRemove = (idx) => () => {
        const { onChange, items } = this.props;
        const newItems = items.slice();

        newItems.splice(idx, 1);

        onChange(newItems);
    }

    _onClickAdd = () => {
        const { onChange, items } = this.props;

        onChange(items.concat([{
            $id: items.reduce((_, cur) => cur.$id + 1, 1),
            value: '',
        }]));
    }

    render() {
        const { items, addButtonText } = this.props;

        return (
            <div>
                {items.map((item, itemIdx) => (
                    <div key={item.$id} className="row ingredient-edit-lang-item">
                        <div className="col-10">
                            <FormInput
                                value={item.value}
                                onChange={this._onChange(itemIdx)}
                            />
                        </div>
                        <div className="col-2">
                            <button onClick={this._onClickRemove(itemIdx)}><Ic.Trash/></button>
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="col-10">
                        <button className="btn btn-secondary btn-block" onClick={this._onClickAdd}>
                            <Ic.SimplePlus />
                            <span>{addButtonText}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default class IngredientEditLanguage extends PureComponent {
    render() {
        const { ingredient, onChange } = this.props;

        return (
            <div className="ingredient-edit-lang">
                <h2>
                    <span className="flag-icon flag-icon-us"></span>
                    <span>{text('IngredientEditLanguage.unitedStates', 'United States')}</span>
                </h2>
                <FormInput
                    placeholder={text('IngredientEditLanguage.name', 'Ingredient Name')}
                    value={ingredient.name}
                    onChange={onChange('name')}
                />
                <div className="row">
                    <div className="col-6">
                        <h3>{text('IngredientEditLanguage.alsoKnownAs', 'Also Known As')}</h3>
                        <p>{text('IngredientEditLanguage.alsoKnownAsInfo', 'Also Known As or “AKA” will link to all the same default nutritional information for the ingredient without changing the familiar name for the user.')}</p>
                    </div>
                    <div className="col-6">
                        <h3>{text('IngredientEditLanguage.misSpellings', 'Popular Mis-Spellings')}</h3>
                        <p>{text('IngredientEditLanguage.misSpellingsInfo', 'Popular Mis-Spellings will auto correct the mis-spelled name to the correct default name for the language.')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <IngredientEditLanguageGroup
                            onChange={onChange('akas')}
                            items={ingredient.akas}
                            addButtonText={text('IngredientEditInfo.addAKA', 'Add an AKA')}
                        />
                    </div>
                    <div className="col-6">
                        <IngredientEditLanguageGroup
                            onChange={onChange('mis_spellings')}
                            items={ingredient.mis_spellings}
                            addButtonText={text('IngredientEditInfo.addMisSpelling', 'Add a Mis-Spelling')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
