import { text } from '@shk/react-simple-intl';
import axios from 'axios';

export const LOAD_QUEUES_REQUEST = 'LOAD_QUEUES_REQUEST';
export const LOAD_QUEUES_SUCCESS = 'LOAD_QUEUES_SUCCESS';
export const LOAD_QUEUES_FAILURE = 'LOAD_QUEUES_FAILURE';

function queueTitle(id) {
    switch(id) {
        case 'ingredient-auto':
        case 'recipe-auto':
            return text('loadQueueAction.autoQueue', 'Auto Queue');
        case 'ingredient-escalated':
        case 'recipe-escalated':
            return text('loadQueueAction.escalated', 'Escalated');
        default:
            return id.replace(/(ingredient|recipe|shopping)-/, '').replace(/-/g, ' ').replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());
    }
}

export default () => (dispatch) => {
    dispatch({ type: LOAD_QUEUES_REQUEST });

    return axios('/api/v1/orchard/queue/counts')
        .then(
            (res) => {
                const data = res.data.hits.reduce((res, cur) => {
                    const idParts = cur.id.match(/(ingredient|recipe|shopping)-(.+)/);

                    cur.title = queueTitle(cur.id);

                    if(idParts) {
                        if(idParts[2] === 'auto') {
                            res[idParts[1]].unshift(cur);
                        } else {
                            res[idParts[1]].push(cur);
                        }
                    }

                    return res;
                }, {
                    ingredient: [],
                    recipe: [],
                    shopping: [],
                    total: res.data.total,
                });

                return dispatch({
                    type: LOAD_QUEUES_SUCCESS,
                    data,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: LOAD_QUEUES_FAILURE,
                    err
                }));
            }
        )
}
