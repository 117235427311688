import axios from 'axios';

export const REFUND_PAYMENT_REQUEST = 'REFUND_PAYMENT_REQUEST';
export const REFUND_PAYMENT_SUCCESS = 'REFUND_PAYMENT_SUCCESS';
export const REFUND_PAYMENT_FAILURE = 'REFUND_PAYMENT_FAILURE';

export default (payment, customerId, paymentGateway, refundInPrepearOnly) => (dispatch) => {

    dispatch({ type: REFUND_PAYMENT_REQUEST });

    return axios.post(`/api/v1/orchard/user-support/refund`, {
        payment,
        customerId,
        paymentGateway,
        refundInPrepearOnly
    })
        .then((res) => dispatch({ type: REFUND_PAYMENT_SUCCESS, success: res.data.success }))
        .catch(err => Promise.reject(dispatch({ type: REFUND_PAYMENT_FAILURE })));
}
