import React, { PureComponent } from 'react';

export default class RecipeMain extends PureComponent {
    render() {
        return (
            <div>
                <h2>Recipe</h2>
            </div>
        );
    }
}
