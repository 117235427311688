import axios from 'axios';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';


function mapSubId(subId) {
    switch(subId) {
        case 'shk_3month':
        case 'full_access_3_month_30':
            return {period:'month', interval: 3 }
        case 'shk_6month':
        case 'full_access_semi_annual_36':
        case 'full_access_semi_annual_48':
        case 'prepear_gold_semi_annual_96':
        case 'prepear_gold_semi_annual_84':
        case 'prepear_gold_semi_annual_72':
            return {period: 'month', interval: 6}
        case 'gold_month':
        case 'full_access_month':
        case 'full_access_month_8':
        case 'full_access_month_10':
        case 'full_access_month_11':
        case 'prepear_gold_month_20':
        case 'prepear_gold_month_18':
        case 'prepear_gold_month_16':
        case 'prepear_gold_month_9':
        case 'prepear_gold_month':
        case 'basic_monthly_15_3month_trial':
        case 'basic_monthly_15':
        case 'com.prepear.monthly':
        case 'com.prepear.ios.monthly':
        case 'com.prepear.ios.1dollar3month':
        case 'com.prepear.android.monthly':
        case 'com.prepear.android.monthly999':
        case 'com.prepear.android.1dollar3month':
            return {period: 'month', interval: 1}
        case 'gold_annual':
        case 'full_access_annual':
        case 'full_access_annual_99':
        case 'full_access_annual_95':
        case 'full_access_annual_59':
        case 'full_access_annual_48':
        case 'full_access_annual_60':
        case 'premium_annual':
        case 'premium_annual_week_trial':
        case 'premium_annual_week_trial_79':
        case 'prepear_gold_annual_120':
        case 'prepear_gold_annual_119':
        case 'prepear_gold_annual_108':
        case 'prepear_gold_annual_96':
        case 'prepear_gold_59':
        case 'prepear_gold_79':
        case 'prepear_gold':
        case 'com.prepear.ios.annual':
        case 'com.prepear.ios.annual59':
        case 'com.prepear.ios.annual79':
        case 'com.prepear.ios.annual119':
        case 'com.prepear.android.annual':
        case 'com.prepear.android.annual59':
        case 'com.prepear.android.annual79':
        case 'com.prepear.android.annual119':
            return {period: 'year', interval: 1}
        default:
            return null;
    }
}

export default (subscription, user) => (dispatch) => {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });
    const {
        billing_amt,
        full_access_purchase_amt,
        has_trialed,
        discount_id,
        full_access_partner_id,
        long_term_referral_id,
        payment_gateway,
        payment_ids,
        plans,
        referral_partner_id,
        short_term_referral_id,
        sub_started,
        expires,
        user_id,
        state,
        isNew,
        sub_id,
    } = subscription;

    const subDetails = mapSubId(sub_id)
    const updateSubscription = {
        customerId: payment_ids && payment_ids[payment_gateway],
        isNew: !!isNew,
        subId: sub_id,
        billingAmt: billing_amt,
        fullAccessPurchaseAmt: full_access_purchase_amt || billing_amt,
        hasTrialed: has_trialed,
        billingInterval: subDetails.interval,
        billingPeriod: subDetails.period,
        discountExpires: expires,
        expires,
        fullAccessPartnerId: full_access_partner_id || null,
        longTermReferralId: long_term_referral_id || null,
        short_term_referral_id: short_term_referral_id || null,
        paymentGateway: payment_gateway,
        plans,
        referralPartnerId: referral_partner_id,
        sub_started,
        state,
        referralSourceId: null,
        discount: discount_id ? {id: discount_id} : {id: null},
    }

    return axios.post(`/api/v1/orchard/user-support/update-subscription`, {
        customerId: user_id,
        subscription: updateSubscription,
        user
    })
    .then((res) => dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS, success: res.data.success,}))
        .catch(err => Promise.reject(dispatch({ type: CANCEL_SUBSCRIPTION_FAILURE })));
}




