import { LOAD_QUEUES_SUCCESS } from "../actions/load-queues-action";
import { RESOLVE_INGRED_ITEM_SUCCESS } from "../routes/ingredients/actions/resolve-queue-item-action";

const DEFAULT_STATE = {
    loading: false,
    recieved: false,
    entities: {
        ingredient: [],
        shopping: [],
        recipe: [],
    },
    total: 0,
};

export default function ingredientReducer(state = DEFAULT_STATE, action) {
    switch(action.type) {
        case LOAD_QUEUES_SUCCESS:
            return { ...state, recieved: true, entities: action.data, total: action.data.total };
        case RESOLVE_INGRED_ITEM_SUCCESS:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ingredient: state.entities.ingredient.map(
                        queue => queue.id === action.queueId
                            ? { ...queue, total: queue.total - 1 }
                            : queue
                    )
                }
            };
        default:
            return state;
    }
}
