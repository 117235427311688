import axios from 'axios';

export const LOAD_CURRENT_QUEUE_ITEMS_REQUEST = 'LOAD_CURRENT_QUEUE_ITEMS_REQUEST';
export const LOAD_CURRENT_QUEUE_ITEMS_SUCCESS = 'LOAD_CURRENT_QUEUE_ITEMS_SUCCESS';
export const LOAD_CURRENT_QUEUE_ITEMS_FAILURE = 'LOAD_CURRENT_QUEUE_ITEMS_FAILURE';

export default (queueId) => (dispatch) => {
    dispatch({ type: LOAD_CURRENT_QUEUE_ITEMS_REQUEST });

    return axios(`/api/v1/orchard/queue/${queueId}`)
        .then(
            (res) => {
                return dispatch({
                    type: LOAD_CURRENT_QUEUE_ITEMS_SUCCESS,
                    data: res.data,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: LOAD_CURRENT_QUEUE_ITEMS_FAILURE,
                    err
                }));
            }
        )
}
