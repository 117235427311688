import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';

import loadCurrentQueueItemsAction from '../actions/load-current-queue-items-action';
import loadNextQueueItemsAction from '../actions/load-next-queue-items-action';
import matchIngredientsAction from '../actions/match-ingredient-action';
import resolveQueueItem from '../actions/resolve-queue-item-action';
import loadIngredientAction from '../actions/load-ingredient-action';

import './IngredientQueueManual.css';

export class IngredientQueueManual extends PureComponent {
    state = {
        current: null,
        ingredient: null,
    }

    componentWillMount() {
        this.props.loadCurrent(this.props.queue.id);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.items !== nextProps.items) {
            this._loadItem(nextProps);
        }

        if(this.props.ingredient !== nextProps.ingredient && nextProps.ingredient) {
            if(nextProps.ingredient.shopping_category) {
                this.props.resolve(nextProps.queue.id, this.state.current.id);
            } else {
                this.props.onEdit(nextProps.ingredient);
            }
        }
    }

    refreshValidation = () => {
    }

    _loadItem = ({ items }) => {
        if(items[0]) {
            this.setState({
                current: items[0],
                ingredient: items[0].queue_item,
            });

            this.props.loadIngredient(items[0].queue_item.id)
        } else {
            this.props.loadNext(this.props.queue.id);
        }
    }

    _renderIngredient = (queue, ingredient) => {
        if(queue.id === 'ingredient-shopping') {
            return (
                <div className="ingredient-queue-ingredient">
                    <h4>Shopping Category:</h4>
                    <p>{ingredient.shopping_category
                        ? ingredient.shopping_category
                        : 'Missing'
                    }</p>
                </div>
            );
        }
    }

    render() {
        const { queue } = this.props;
        const { ingredient } = this.state;

        return (
            <div className="ingredient-queue">
                <h2>
                    <span>{queue.title}</span>
                    <small>{text('IngredientQueue.queueCount', '{total, number}', queue)}</small>
                </h2>
                {ingredient && this._renderIngredient(queue, ingredient)}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        items: state.ingredientQueue.items,
        loading: state.ingredientQueue.loading,
        ingredient: state.ingredient.details,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadCurrent: (queueId) => {
            dispatch(loadCurrentQueueItemsAction(queueId));
        },
        loadNext: (queueId) => {
            dispatch(loadNextQueueItemsAction(queueId));
        },
        matchIngredients: (ingredients) => {
            return dispatch(matchIngredientsAction(ingredients));
        },
        resolve: (queueId, queueItemId, ingredient) => {
            return dispatch(resolveQueueItem(queueId, queueItemId, ingredient));
        },
        loadIngredient: (ingredientId) => {
            return dispatch(loadIngredientAction(ingredientId));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(IngredientQueueManual);
