import axios from 'axios';

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const userLoggedInAction = (data) => (dispatch) => {
    dispatch({
        type: LOAD_USER_SUCCESS,
        data,
    });
}

export default () => (dispatch) => {
    dispatch({ type: LOAD_USER_REQUEST });

    return axios('/api/v1/orchard/auth')
        .then(
            (res) => {
                return dispatch({
                    type: LOAD_USER_SUCCESS,
                    data: res.data,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: LOAD_USER_FAILURE,
                    err
                }));
            }
        )
}
