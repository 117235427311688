import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { text } from '@shk/react-simple-intl';
import Ic from '@shk/prepear-controls/controls/icons';
import LoginForm from '@shk/prepear-controls/features/LoginForm';

import loadUserAction, { userLoggedInAction } from '../../actions/load-user-action';
import loadQueuesAction from '../../actions/load-queues-action';

import ochardLogo from '../../assets/img/orchard-logo.svg';
import WrapNavSection from './WrapNavSection';

import './Wrap.css';

export class Wrap extends PureComponent {
    state = {
        loggedIn: false,
    }

    componentDidMount() {
        this.props.loadUser()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user && !this.props.user) {
            this.props.loadQueues();
        }
    }

    render() {
        const { children, user, recieved, location, queues } = this.props;

        if(!recieved) {
            return null;
        }

        const customerPage = location.pathname.includes('/user-support/customer/');
        if(user) {
            return (
                <div className="wrap-outer">
                    {!customerPage && <nav>
                        <header>
                            <Link to="/home">
                                <img src={ochardLogo} alt="orchard"/>
                            </Link>
                        </header>
                        <ul>
                            <WrapNavSection
                                to="/ingredients"
                                Icon={Ic.Recipes}
                                label={text('Wrap.navIngredients', 'Ingredients')}
                                queues={queues.ingredient}
                            />
                            <WrapNavSection
                                to="/shopping"
                                Icon={Ic.Bookmark}
                                label={text('Wrap.navRecipes', 'Shopping')}
                                queues={queues.shopping}
                            />
                            {/* <WrapNavSection
                                to="/recipes"
                                Icon={Ic.Bookmark}
                                label={text('Wrap.navRecipes', 'Recipes')}
                                queues={queues.recipe}
                            /> */}
                            <WrapNavSection
                                to="/user-support"
                                Icon={Ic.User}
                                label={text('Wrap.navRecipes', 'User Support')}
                            />
                            <WrapNavSection
                                to="/admin"
                                Icon={Ic.User}
                                label={text('Wrap.navAdmin', 'Admin')}
                            />
                        </ul>
                    </nav>}
                    <div className={customerPage ? "wrap-content no-nav cf" : "wrap-content cf"}>
                        <main>
                            {children}
                        </main>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="wrap-auth">
                    <header>
                        <img src={ochardLogo} alt="orchard"/>
                    </header>
                    <LoginForm
                        redirectUrl="/orchard"
                        authLoginUrl="/api/v1/orchard/auth"
                        location={location}
                        onLoggedIn={this.props.loggedIn}
                    />
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.entity,
        recieved: state.user.recieved,
        queues: state.queue.entities,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loggedIn: (data) => {
            dispatch(userLoggedInAction(data));
        },
        loadUser: () => {
            dispatch(loadUserAction());
        },
        loadQueues: () => {
            dispatch(loadQueuesAction());
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wrap));
