import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';
import { ingredients } from '@shk/common-libs';

import loadCurrentQueueItemsAction from '../actions/load-current-queue-items-action';
import loadNextQueueItemsAction from '../actions/load-next-queue-items-action';
import matchIngredientsAction from '../actions/match-ingredient-action';
import resolveQueueItem from '../actions/resolve-queue-item-action';
import escalateQueueItem from '../actions/escalate-queue-item-action';

import { FormInput } from '@shk/prepear-controls/controls/form';
import UserPicture from '@shk/prepear-controls/controls/common/UserPicture';
import Ic from '@shk/prepear-controls/controls/icons';

import './IngredientQueueAuto.css';

const INGREDIENT_FRACTION_MAP = {
    '½': '1/2',
    '↉': '',
    '⅓': '1/3',
    '⅔': '2/3',
    '¼': '1/4',
    '¾': '3/4',
    '⅕': '1/5',
    '⅖': '2/5',
    '⅗': '3/5',
    '⅘': '4/5',
    '⅙': '1/6',
    '⅚': '5/6',
    '⅐': '1/7',
    '⅛': '1/8',
    '⅜': '3/8',
    '⅝': '5/8',
    '⅞': '7/8',
    '⅑': '1/9',
    '⅒': '1/10',
    '⅟': '1/'
};

export class IngredientQueueAuto extends PureComponent {
    loadNextTries = 0

    state = {
        selected: 0,
        loadingNext: true,
        autoResolved: 0,
        matchMeasurements: [],
        showMove: false,
        escalate: '',
    }

    componentWillMount() {
        this.props.loadCurrent(this.props.queue.id);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.items !== nextProps.items) {
            this._loadItem(this.state.selected, nextProps);
        }

        if(this.props.queue.id !== nextProps.queue.id) {
            this.props.loadCurrent(nextProps.queue.id);
        }
    }

    refreshValidation = () => {
        this._matchIngredientName(this.state.ingredient.name, true);
    }

    _loadItem = (selected, { items }) => {
        if(items[selected]) {
            this.loadNextTries = 0;

            this.setState({
                selected,
                current: items[selected],
                ingredient: items[selected].queue_item,
            }, this.refreshValidation);
        } else if(this.loadNextTries < 10) {
            this.setState({
                selected,
                current: null,
                ingredient: null,
                loadingNext: true,
            });

            this.loadNextTries++;

            this.props.loadNext(this.props.queue.id);
        }
    }

    _prepearMeasurementValue = (measurement) => {
        if (this.state.matchMeasurements.find(x => x.value === measurement)) {
            return measurement;
        }

        return (measurement || '').trim().toLowerCase().replace(/[^a-z\s#]/g, '');
    }

    _isValidAmount = (value, match) => {
        return ingredients.verifyAmount(match, value)
    }

    _isValidMeasurement = (value, match) => {
        return ingredients.verifyMeasurement(match, value);
    }

    _matchIngredientName = (name, immediate) => {
        clearTimeout(this.pendingMatch);

        this.props.onChangeQuickAlias('');

        this.pendingMatch = setTimeout(() => {
            this.props.matchIngredients([name])
                .then(
                    action => {
                        const { autoResolved, loadingNext } = this.state;
                        const ingredientMatch = action.data[0];
                        const ingredientChanges = {
                            ...this.state.ingredient,
                            verified: !!ingredientMatch.match,
                            id: ingredientMatch.match ? ingredientMatch.match.id : null
                        };

                        ingredientChanges.amount = ingredientChanges.amount.replace(/(½|↉|⅓|⅔|¼|¾|⅕|⅖|⅗|⅘|⅙|⅚|⅐|⅛|⅜|⅝|⅞|⅑|⅒|⅟)/g, (m) => ' ' + INGREDIENT_FRACTION_MAP[m]).replace(/\s+/g, ' ').trim()

                        const amountVerified = loadingNext && this._isValidAmount(ingredientChanges.amount, ingredientMatch.match);
                        const measurementVerified = loadingNext && this._isValidMeasurement(ingredientChanges.measurement, ingredientMatch.match);
                        const shouldAutoResolve = loadingNext && ingredientChanges.verified && amountVerified && measurementVerified;

                        const matchMeasurements = ingredientMatch.match
                            ? ingredientMatch.match.measurements.map(measure => ({ text: measure.name, value: measure.name }))
                            : [];

                        if(ingredientMatch.match && !ingredientMatch.match.empty_allowed) {
                            matchMeasurements.unshift({ text: '', value: '' });
                        }

                        if(ingredientMatch.match && ingredientMatch.match.mis_spelt) {
                            ingredientChanges.name = ingredientMatch.match.name;
                        }

                        this.props.onChangeQuickAlias(!ingredientMatch.match ? ingredientChanges.name : '');

                        this.setState({
                            match: ingredientMatch.match,
                            matchMeasurements,
                            ingredient: ingredientChanges,
                            loadingNext: shouldAutoResolve,
                            autoResolved: shouldAutoResolve ? autoResolved + 1 : 0
                        }, () => {
                            if(shouldAutoResolve) {
                                const queueId = this.props.queue.id;
                                const { ingredient, current } = this.state;

                                this.props.resolve(queueId, current.id, ingredient);
                            }
                        });
                    }
                );
        }, immediate ? 0 : 300);
    }

    _onChangeIngredient = (prop) => (e) => {
        const { ingredient } = this.state;
        const { value } = e.target;

        if(prop === 'name') {
            this._matchIngredientName(value);
        }

        this.setState({
            ingredient: {
                ...ingredient,
                [prop]: value,
            }
        });
    }

    _onClickSkip = () => {
        this.setState({ loadingNext: true, autoResolved: 0 });

        this._loadItem(this.state.selected + 1, this.props);
    }

    _onClickResolve = () => {
        const queueId = this.props.queue.id;
        const { ingredient, current } = this.state;

        this.setState({ loadingNext: true, autoResolved: 0 });

        this.props.resolve(queueId, current.id, ingredient);
    }

    _onPressShowMove = (showMove) => () => {
        this.setState({ showMove });
    }

    _onChangeEscalate = (e) => {
        this.setState({ escalate: e.target.value });
    }

    _onPressSaveEscalation = () => {
        const queueId = this.props.queue.id;
        const { current } = this.state;

        this.props.escalate(queueId, current.id, this.state.escalate);

        this.setState({ showMove: false, escalate: '' });
    }

    render() {
        const { queue, loading } = this.props;
        const { ingredient, current, matchMeasurements, loadingNext, autoResolved, match, showMove, escalate } = this.state;

        const amountVerified = ingredient && this._isValidAmount(ingredient.amount, match);
        const measurementVerified = ingredient && this._isValidMeasurement(ingredient.measurement, match);
        const measurementVal = this._prepearMeasurementValue(ingredient && ingredient.measurement);
        const allVerified = ingredient && ingredient.verified && amountVerified && measurementVerified;
        const actionsDisabled = loadingNext || loading || !ingredient;

        return (
            <div className="ingredient-queue">
                <h2>
                    <span>{queue.title}</span>
                    <small>{text('IngredientQueue.queueCount', '{total, number}', queue)}</small>
                </h2>
                {!!current && !loadingNext && current.owner && <h4 className="ingredient-queue-user">
                    <UserPicture user={current.owner} size={30} />
                    {current.owner_submitted
                        ? <span>{text('IngredientQueue.submittedBy', 'Submitted by {display_name}', current.owner)}</span>
                        : <span>{text('IngredientQueue.forUser', 'For {display_name}', current.owner)}</span>
                    }
                </h4>}
                {!!current && !loadingNext && <div className="ingredient-queue-ingredient">
                    <div className="ingredient-queue-ingredient-header">
                        <div>{text('IngredientQueue.ingredientAmount', '{amount} {measurement}', current.queue_item)}</div>
                        <div>{
                            current.queue_item.note
                                ? text('IngredientQueue.ingredientNameWithNote', '{name} ({note})', current.queue_item)
                                :text('IngredientQueue.ingredientName', '{name}', current.queue_item)
                        }</div>
                        {!!current.queue_item.recipe_id && <a href={`https://app.prepear.com/app/discover/recipes?recipe=${current.queue_item.recipe_id}`} target="_blank"><Ic.View/></a>}
                    </div>
                    <FormInput
                        label={text('IngredientQueue.ingredientNameField', 'Ingredient Name')}
                        Icon={ingredient.verified ? Ic.Verified : Ic.Question}
                        value={ingredient.name || ''}
                        onChange={this._onChangeIngredient('name')}
                    />
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientQueue.ingredientAmountField', 'Amount')}
                                Icon={amountVerified ? Ic.Verified : Ic.Question}
                                value={ingredient.amount || ''}
                                onChange={this._onChangeIngredient('amount')}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                type="select"
                                options={matchMeasurements}
                                label={text('IngredientQueue.ingredientMeasurementField', 'Measurement')}
                                Icon={measurementVerified ? Ic.Verified : Ic.Question}
                                value={measurementVal}
                                onChange={this._onChangeIngredient('measurement')}
                            />
                        </div>
                    </div>
                    <FormInput
                        type="textarea"
                        label={text('IngredientQueue.ingredientDetailField', 'Detail')}
                        value={ingredient.note || ''}
                        onChange={this._onChangeIngredient('note')}
                    />
                </div>}
                {loadingNext && <div className="ingredient-queue-loadingnext">
                    <h3>{text('IngredientQueue.loadingNext', 'Searching for next...')}</h3>
                    <p>{text('IngredientQueue.resolveInfo', `Auto resolved {count, plural,
                        one {1 ingredient}
                        other {# ingredients}
                    } while looking for the next work item.`, { count: autoResolved })}</p>
                </div>}
                <div className="ingredient-queue-actions">
                    <div className="row">
                        <div className="col-2">
                            {!queue.id.match(/escalated/) && <button className="btn btn-block" onClick={this._onPressShowMove(true)} style={{ padding: '6px 6px 6px 5.5px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ display: 'block' }}>
                                    <path fill="#4D4840" fillRule="evenodd" d="M13,9 C13,8.447 12.552,8 12,8 C11.448,8 11,8.447 11,9 L11,11 L9,11 C8.448,11 8,11.447 8,12 C8,12.553 8.448,13 9,13 L11,13 L11,15 C11,15.553 11.448,16 12,16 C12.552,16 13,15.553 13,15 L13,13 L15,13 C15.552,13 16,12.553 16,12 C16,11.447 15.552,11 15,11 L13,11 L13,9 Z M17,21 L14.871,21 C14.237,20.094 13.187,19.5 12,19.5 C10.813,19.5 9.762,20.094 9.129,21 L7,21 L7,3 L9.129,3 C9.762,3.906 10.813,4.5 12,4.5 C13.187,4.5 14.237,3.906 14.871,3 L17,3 L17,21 Z M15.505,1 L15.5,1 L13.5,1 C13.5,1.828 12.828,2.5 12,2.5 C11.171,2.5 10.5,1.828 10.5,1 L5,1 L5,23 L10.5,23 C10.5,22.171 11.171,21.5 12,21.5 C12.828,21.5 13.5,22.171 13.5,23 L19,23 L19,1 L15.505,1 Z"/>
                                </svg>
                            </button>}
                        </div>
                        <div className="col-5">
                            <button className="btn btn-block" disabled={actionsDisabled} onClick={this._onClickSkip}>{text('IngredientQueue.actionSkip', 'Skip')}</button>
                        </div>
                        <div className="col-5">
                            <button className="btn btn-block btn-primary" disabled={actionsDisabled || !allVerified} onClick={this._onClickResolve}>{text('IngredientQueue.actionNext', 'Next')}</button>
                        </div>
                    </div>
                </div>
                {showMove && <div className="ingredient-queue-move">
                    <h4>{text('IngredientQueue.escalate', 'Escalate')}</h4>

                    <FormInput
                        type="textarea"
                        label={text('IngredientQueue.escalateNote', 'Note for Ingredient Taxonomy')}
                        value={escalate}
                        onChange={this._onChangeEscalate}
                    />
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-block" onClick={this._onPressShowMove(false)}>{text('IngredientQueue.escalateCancel', 'Cancel')}</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-block btn-primary" onClick={this._onPressSaveEscalation}>{text('IngredientQueue.escalateSave', 'Save')}</button>
                        </div>
                    </div>
                </div>}
                {current && current.note && <div className="ingredient-queue-note">
                    <h5>{text('IngredientQueue.note', 'Note:')}</h5>
                    <p><em>{current.note}</em></p>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        items: state.ingredientQueue.items,
        loading: state.ingredientQueue.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadCurrent: (queueId) => {
            dispatch(loadCurrentQueueItemsAction(queueId));
        },
        loadNext: (queueId) => {
            dispatch(loadNextQueueItemsAction(queueId));
        },
        matchIngredients: (ingredients) => {
            return dispatch(matchIngredientsAction(ingredients));
        },
        resolve: (queueId, queueItemId, ingredient) => {
            return dispatch(resolveQueueItem(queueId, queueItemId, ingredient));
        },
        escalate: (queueId, queueItemId, note) => {
            return dispatch(escalateQueueItem(queueId, queueItemId, note));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(IngredientQueueAuto);
