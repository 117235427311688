import axios from 'axios';

export const CREATE_PRO_REQUEST = 'CREATE_PRO_REQUEST';
export const CREATE_PRO_SUCCESS = 'CREATE_PRO_SUCCESS';
export const CREATE_PRO_FAILURE = 'CREATE_PRO_FAILURE';

export default (publisherEmail, subscriberEmail, allowedHostname) => (dispatch) => {

    dispatch({ type: CREATE_PRO_REQUEST });

    return axios.post('/api/v1/orchard/admin/create-pro', {
        publisherEmail,
        subscriberEmail,
        allowedHostname,
    })
        .then(() => dispatch({ type: CREATE_PRO_SUCCESS }))
        .catch(err => Promise.reject(dispatch({ type: CREATE_PRO_FAILURE })));
}
