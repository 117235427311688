import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import Ic from '@shk/prepear-controls/controls/icons';
import { text } from '@shk/react-simple-intl';

export class WrapNavSectionItem extends Component {
    static propTypes = {
        label: PropTypes.string,
        to: PropTypes.string,
    }

    render() {
        const { label, to } = this.props;

        return (
            <Route
                path={to}
                children={({ match }) => (
                    <li className={match ? 'active' : ''}>
                        <Link to={to} data-total={text('WrapNavSectionItem.total', '{total, number}', this.props)}>{label}</Link>
                    </li>
                )}
            />
        );
    }
}

export default class WrapNavSection extends Component {
    static propTypes = {
        queues: PropTypes.array,
        Icon: PropTypes.func,
        label: PropTypes.string,
        to: PropTypes.string,
    }

    state = {
        opened: false
    }

    _onClickOpen = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    render() {
        const { queues, Icon, label, to } = this.props;
        const { opened } = this.state;

        const hasQueues = !!(queues && queues.length);

        return (
            <Route
                path={to}
                children={({ match }) => (
                    <li className={(match || opened) ? 'active' : ''}>
                        <Link to={to}>
                            <Icon/>
                            <span>{label}</span>
                        </Link>
                        {hasQueues && <ul style={{height: 30 * queues.length}}>
                            {queues.map(queue => (
                                <WrapNavSectionItem
                                    key={queue.id}
                                    to={to + '/queue/' + queue.id}
                                    label={queue.title}
                                    total={queue.total}
                                />
                            ))}
                        </ul>}
                        {hasQueues && <button className="expandable" onClick={this._onClickOpen}>
                            <Ic.ArrowDown/>
                        </button>}
                    </li>
                )}
            />
        );
    }
}
