import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/user-reducer';
import queueReducer from './reducers/queue-reducer';
import ingredientReducer from './routes/ingredients/reducers/ingredient-reducer';
import ingredientQueueReducer from './routes/ingredients/reducers/ingredient-queue-reducer';

const reducers = {
    user: userReducer,
    queue: queueReducer,
    ingredient: ingredientReducer,
    ingredientQueue: ingredientQueueReducer,
};

export default function initStore(initialState) {
	const store = createStore(
            buildReducer(reducers),
            initialState,
			applyMiddleware(thunk)
        );

    store.asyncReducers = {...reducers};
    store.injectReducer = function(key, reducer) {
        this.asyncReducers[key] = reducer;
        this.replaceReducer(buildReducer(this.asyncReducers))
    };

    return store;
};

function buildReducer(asyncReducers) {
    return combineReducers({
        ...asyncReducers
    });
}
