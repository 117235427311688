import axios from 'axios';

export const SAVE_INGREDIENT_REQUEST = 'SAVE_INGREDIENT_REQUEST';
export const SAVE_INGREDIENT_SUCCESS = 'SAVE_INGREDIENT_SUCCESS';
export const SAVE_INGREDIENT_FAILURE = 'SAVE_INGREDIENT_FAILURE';

function guardArray(arr, transform) {
    if(arr && arr.length) {
        return transform ? arr.map(transform) : arr;
    } else {
        return null;
    }
}

export default (ingredient) => (dispatch) => {
    dispatch({ type: SAVE_INGREDIENT_REQUEST });

    var standardMeasurement = null;

    if(ingredient.standard_amount && ingredient.standard_measurement && ingredient.standard_grams) {
        standardMeasurement = {
            name:  ingredient.standard_measurement,
            grams: (parseFloat(ingredient.standard_amount) * parseFloat(ingredient.standard_grams))
        };
    }

    return axios.post('/api/v1/orchard/ingredients', {
        id: ingredient.id,
        name: (ingredient.name || '').trim(),
        name_id: ingredient.name_id,
        measurements: guardArray(ingredient.measurements, x => ({
            name: (x.name || '').trim(),
            grams: (parseFloat(x.amount) * parseFloat(x.grams))
        })),
        nutrient_grams: parseInt(ingredient.nutrient_grams, 10),
        nutrients: Object.keys(ingredient.nutrients).reduce((res, key) => {
            const value = parseFloat(ingredient.nutrients[key]);

            if(value) {
                res[key] = value;
            }

            return res;
        }, {}),
        category: ingredient.category,
        sub_categories: guardArray(ingredient.sub_categories),
        shopping_category: ingredient.shopping_category,
        allergies: guardArray(ingredient.allergies),
        seasons: guardArray(ingredient.seasons),
        classifications: guardArray(ingredient.classifications),
        prep_days_raw: parseInt(ingredient.prep_days_raw, 10),
        prep_days_cooked: parseInt(ingredient.prep_days_cooked, 10),
        standard_measurement: standardMeasurement,
        akas: guardArray(ingredient.akas, x => (x.value || '').trim()),
        aka_name_ids: ingredient.aka_name_ids,
        mis_spellings: guardArray(ingredient.mis_spellings, x => (x.value || '').trim()),
        mis_spelling_name_ids: ingredient.mis_spelling_name_ids,
    })
        .then(
            (res) => {
                const newIngredient = res.data.ingredient;

                return dispatch({
                    type: SAVE_INGREDIENT_SUCCESS,
                    ingredient: {
                        ...ingredient,
                        id: newIngredient.id,
                        name_id: newIngredient.name_id,
                        aka_name_ids: newIngredient.aka_name_ids,
                        mis_spelling_name_ids: newIngredient.mis_spelling_name_ids,
                    },
                });
            }
        )
}
