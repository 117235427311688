import axios from 'axios';
import Fraction from 'fraction.js';

export const MATCH_INGREDIENT_REQUEST = 'MATCH_INGREDIENT_REQUEST';
export const MATCH_INGREDIENT_SUCCESS = 'MATCH_INGREDIENT_SUCCESS';
export const MATCH_INGREDIENT_FAILED = 'MATCH_INGREDIENT_FAILED';

const STANDARD_MEASUREMENTS_NAMES = [];
const STANDARD_MEASUREMENTS = expandStandards({
    // Liquid based Measurements
    drop:           { fl_oz: new Fraction(1, 576),    name: 'drop',         plural: 'drops',         aka: ['drops'] },
    smidgen:        { fl_oz: new Fraction(1, 256),    name: 'smidgen',      plural: 'smidgens',      aka: ['smidgens'] },
    pinch:          { fl_oz: new Fraction(1, 128),    name: 'pinch',        plural: 'pinches',       aka: ['pinches'] },
    dash:           { fl_oz: new Fraction(1, 64),     name: 'dash',         plural: 'dashes',        aka: ['dashes'] },
    saltspoon:      { fl_oz: new Fraction(1, 32),     name: 'saltspoon',    plural: 'saltspoons',    aka: ['saltspoons'] },
    scruple:        { fl_oz: new Fraction(1, 32),     name: 'scruple',      plural: 'scruples',      aka: ['scruples'] },
    coffeespoon:    { fl_oz: new Fraction(1, 16),     name: 'coffeespoon',  plural: 'coffeespoons',  aka: ['coffeespoons'] },
    'fluid dram':   { fl_oz: new Fraction(1, 8),      name: 'fluid dram',   plural: 'fluid drams',   aka: ['fluid drams'] },
    teaspoon:       { fl_oz: new Fraction(1, 6),      name: 'teaspoon',     plural: 'teaspoons',     aka: ['t', 'tsp', 'tsps', 'teaspoons'] },
    dessertspoon:   { fl_oz: new Fraction(1, 3),      name: 'dessertspoon', plural: 'dessertspoons', aka: ['dessertspoons'] },
    tablespoon:     { fl_oz: new Fraction(1, 2),      name: 'tablespoon',   plural: 'tablespoons',   aka: ['T', 'tbl', 'tbs', 'tbsp', 'tablespoons'] },
    'fluid ounce':  { fl_oz: new Fraction(1, 1),      name: 'fluid ounce',  plural: 'fluid ounces',  aka: ['fl oz', 'fluid ounces'] },
    wineglass:      { fl_oz: new Fraction(2, 1),      name: 'wineglass',    plural: 'wineglasses',   aka: ['wineglasses'] },
    gill:           { fl_oz: new Fraction(4, 1),      name: 'gill',         plural: 'gills',         aka: ['gills'] },
    teacup:         { fl_oz: new Fraction(4, 1),      name: 'teacup',       plural: 'teacups',       aka: ['teacups'] },
    cup:            { fl_oz: new Fraction(8, 1),      name: 'cup',          plural: 'cups',          aka: ['c', 'cups'] },
    pint:           { fl_oz: new Fraction(16, 1),     name: 'pint',         plural: 'pints',         aka: ['p', 'pt', 'fl pt', 'pints'] },
    quart:          { fl_oz: new Fraction(32, 1),     name: 'quart',        plural: 'quarts',        aka: ['quarts'] },
    pottle:         { fl_oz: new Fraction(64, 1),     name: 'pottle',       plural: 'pottles',       aka: ['pottles'] },
    gallon:         { fl_oz: new Fraction(128, 1),    name: 'gallon',       plural: 'gallons',       aka: ['g', 'gal', 'gals', 'gallons'] },
    milliliter:     { fl_oz: new Fraction(1, 30),     name: 'milliliter',   plural: 'milliliters',   aka: ['ml', 'mililitre', 'cc', 'milliliters'] },
    deciliter:      { fl_oz: new Fraction(3, 30),     name: 'deciliter',    plural: 'deciliters',    aka: ['dl', 'decilitre', 'decilitres', 'deciliters'] },
    liter:          { fl_oz: new Fraction(33, 30),    name: 'liter',        plural: 'liters',        aka: ['l', 'litre', 'litres'] },

    // Mass based Measurements
    ounce:          { amount: new Fraction(28.3495),  name: 'ounce',        plural: 'ounces',        aka: ['oz', 'ounces'] },
    pound:          { amount: new Fraction(453.59),   name: 'pound',        plural: 'pounds',        aka: ['lb', 'lbs', '#', 'pounds'] },
    gram:           { amount: new Fraction(1),        name: 'gram',         plural: 'grams',         aka: ['g', 'gramme', 'grams'] },
    kilogram:       { amount: new Fraction(1000),     name: 'kilogram',     plural: 'kilograms',     aka: ['kg', 'kilogramme', 'kilogrammes', 'kilogram'] },
});

function expandStandards(def) {
    const FL_OZ_TO_GRAMS = new Fraction(29.5735);

    Object.keys(def).forEach(key => {
        const value = def[key];

        if(value.fl_oz) {
            value.amount = value.fl_oz.mul(FL_OZ_TO_GRAMS);
        }

        STANDARD_MEASUREMENTS_NAMES.push(key);

        value.aka.forEach(alias => {
            def[alias] = value;

            STANDARD_MEASUREMENTS_NAMES.push(alias);
        });
    });

    STANDARD_MEASUREMENTS_NAMES.sort();

    return def;
}

export default (ingredients) => (dispatch) => {
    dispatch({ type: MATCH_INGREDIENT_REQUEST });

    return axios.post('/api/v1/ingredients', {
        ingredients,
    })
        .then(
            res => {
                if(res.data.error) {
                    return Promise.reject(dispatch({
                        type: MATCH_INGREDIENT_FAILED,
                        error: res.data
                    }));
                } else {
                    const data = res.data.ingredients;

                    data.forEach(item => {
                        if(item.match && item.match.standard_ratio) {
                            const measurements = (item.match.measurements || []).reduce((res, cur) => {
                                res[cur.name] = cur.grams;

                                return res;
                            }, {});

                            STANDARD_MEASUREMENTS_NAMES.forEach(name => {
                                if(!measurements[name]) {
                                    measurements[name] = STANDARD_MEASUREMENTS[name].amount.div(item.match.standard_ratio).valueOf();
                                }
                            });

                            if(measurements['']) {
                                item.match.empty_allowed = true;
                            }

                            item.match.measurements = Object.keys(measurements).map(x => ({
                                name: x,
                                grams: measurements[x]
                            }));
                        }
                    });

                    return dispatch({
                        type: MATCH_INGREDIENT_SUCCESS,
                        data,
                    });
                }
            }
        );
};

