
export const CANCEL_AT_PERIOD_END = 'CANCEL_AT_PERIOD_END';
export const CANCEL_AT_TRIAL_END = 'CANCEL_AT_TRIAL_END';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CANCELED = 'CANCELED';
export const IN_TRIAL = 'IN_TRIAL';
export const ACTIVE =  'ACTIVE';
export const INVOICE_CREATED = 'INVOICE_CREATED'
export const RETRY_PAYMENT = 'RETRY_PAYMENT';
export const GIFT = 'GIFT';
export const PREPEAR_PRO = 'PREPEAR_PRO';


export const STATE_OPTIONS = [
    {text: 'Active', value: ACTIVE},
    {text: 'Canceled', value: CANCELED},
    {text: 'Gift', value: GIFT},
    {text: 'In Trial', value: IN_TRIAL},
    {text: 'Cancel at Period End', value: CANCEL_AT_PERIOD_END},
    {text: 'Cancel Aa Trial End', value: CANCEL_AT_TRIAL_END},
    {text: 'Retry Payment', value: RETRY_PAYMENT},
    {text: 'Payment Failed', value: PAYMENT_FAILED},
    {text: 'Invoice Created', value: INVOICE_CREATED},
    {text: 'Prepear Pro', value: PREPEAR_PRO},
];

export const CANCELED_STATES = [
    CANCEL_AT_PERIOD_END,
    CANCEL_AT_TRIAL_END,
    PAYMENT_FAILED,
    CANCELED
];

export const PENDING_STATES = [
    IN_TRIAL,
    RETRY_PAYMENT,
    INVOICE_CREATED,
];

export const ACTIVE_STATES = [
    ACTIVE,
    GIFT,
    PREPEAR_PRO,
];

export const IOS_SUB_TYPES = [
    {text:'com.prepear.ios.monthly', value: 'com.prepear.ios.monthly'},
    {text:'com.prepear.ios.1dollar3month', value:'com.prepear.ios.1dollar3month'},
    {text: 'com.prepear.ios.annual', value: 'com.prepear.ios.annual'},
    {text:'com.prepear.ios.annual59', value:'com.prepear.ios.annual59'},
    {text: 'com.prepear.ios.annual79', value: 'com.prepear.ios.annual79'},
    {text: 'com.prepear.ios.annual119', value: 'com.prepear.ios.annual119'}
]

export const ANDROID_SUB_TYPES = [
    {text:'com.prepear.android.monthly', value: 'com.prepear.android.monthly'},
    {text:'com.prepear.android.1dollar3month', value:'com.prepear.android.1dollar3month'},
    {text: 'com.prepear.android.annual', value: 'com.prepear.android.annual'},
    {text:'com.prepear.android.annual59', value:'com.prepear.android.annual59'},
    {text: 'com.prepear.android.annual79', value: 'com.prepear.android.annual79'},
    {text: 'com.prepear.android.annual119', value: 'com.prepear.android.annual119'},
    {text: 'com.prepear.android.monthly999', value: 'com.prepear.android.monthly999'},
]

export const STRIPE_AND_PAYPAL_SUB_TYPES = [
    {text: 'Select a Subscription Type',  value: 'select'},
    {text:'full_access_annual', value: 'full_access_annual'},
    {text:'gold_annual', value: 'gold_annual'},
    {text:'gold_month', value: 'gold_month'},
    {text:'full_access_month', value: 'full_access_month'},

    {text:'full_access_3_month_30', value: 'full_access_3_month_30'},
    {text:'full_access_semi_annual_36', value: 'full_access_semi_annual_36'},
    {text:'full_access_semi_annual_48', value: 'full_access_semi_annual_48'},
    {text:'prepear_gold_semi_annual_96', value: 'prepear_gold_semi_annual_96'},
    {text:'prepear_gold_semi_annual_84', value: 'prepear_gold_semi_annual_84'},
    {text:'prepear_gold_semi_annual_72', value: 'prepear_gold_semi_annual_72'},
    {text:'full_access_month_8', value: 'full_access_month_8'},
    {text:'full_access_month_10', value: 'full_access_month_10'},
    {text:'full_access_month_11', value: 'full_access_month_11'},
    {text:'prepear_gold_month_20', value: 'prepear_gold_month_20'},
    {text:'prepear_gold_month_18', value: 'prepear_gold_month_18'},
    {text:'prepear_gold_month_16', value: 'prepear_gold_month_16'},
    {text:'prepear_gold_month_9', value: 'prepear_gold_month_9'},
    {text:'prepear_gold_month', value: 'prepear_gold_month'},
    {text:'basic_monthly_15_3month_trial', value: 'basic_monthly_15_3month_trial'},
    {text:'basic_monthly_15', value: 'basic_monthly_15'},
    {text:'full_access_annual_99', value: 'full_access_annual_99'},
    {text:'full_access_annual_95', value: 'full_access_annual_95'},
    {text:'full_access_annual_59', value: 'full_access_annual_59'},
    {text:'full_access_annual_48', value: 'full_access_annual_48'},
    {text:'full_access_annual_60', value: 'full_access_annual_60'},
    {text:'premium_annual', value: 'premium_annual'},
    {text:'premium_annual_week_trial', value: 'premium_annual_week_trial'},
    {text:'premium_annual_week_trial_79', value: 'premium_annual_week_trial_79'},
    {text:'prepear_gold_annual_120', value: 'prepear_gold_annual_120'},
    {text:'prepear_gold_annual_119', value: 'prepear_gold_annual_119'},
    {text:'prepear_gold_annual_108', value: 'prepear_gold_annual_108'},
    {text:'prepear_gold_annual_96', value: 'prepear_gold_annual_96'},
    {text:'prepear_gold_59', value: 'prepear_gold_59'},
    {text:'prepear_gold_79', value: 'prepear_gold_79'},
    {text:'prepear_gold', value: 'prepear_gold'},
    {text:'shk_3month', value: 'shk_3month'},
    {text:'shk_6month', value: 'shk_6month'},
]


export function isFullAccess(subId) {
    switch(subId) {
        case 'full_access_annual':
        case 'full_access_annual_99':
        case 'full_access_annual_95':
        case 'full_access_annual_59':
        case 'full_access_annual_48':
        case 'full_access_annual_60':
        case 'full_access_month':
        case 'full_access_month_8':
        case 'full_access_month_10':
        case 'full_access_month_11':
        case 'full_access_3_month_30':
        case 'full_access_semi_annual_36':
        case 'full_access_semi_annual_48':
            return true;
        case 'shk_6month':
        case 'prepear_gold_semi_annual_96':
        case 'prepear_gold_semi_annual_84':
        case 'prepear_gold_semi_annual_72':
        case 'gold_month':
        case 'prepear_gold_month_20':
        case 'prepear_gold_month_18':
        case 'prepear_gold_month_16':
        case 'prepear_gold_month_9':
        case 'prepear_gold_month':
        case 'basic_monthly_15_3month_trial':
        case 'basic_monthly_15':
        case 'com.prepear.monthly':
        case 'com.prepear.ios.monthly':
        case 'com.prepear.ios.1dollar3month':
        case 'com.prepear.android.monthly':
        case 'com.prepear.android.monthly999':
        case 'com.prepear.android.1dollar3month':
        case 'gold_annual':
        case 'premium_annual':
        case 'premium_annual_week_trial':
        case 'premium_annual_week_trial_79':
        case 'prepear_gold_annual_120':
        case 'prepear_gold_annual_119':
        case 'prepear_gold_annual_108':
        case 'prepear_gold_annual_96':
        case 'prepear_gold_59':
        case 'prepear_gold_79':
        case 'prepear_gold':
        case 'com.prepear.ios.annual':
        case 'com.prepear.ios.annual59':
        case 'com.prepear.ios.annual79':
        case 'com.prepear.ios.annual119':
        case 'com.prepear.android.annual':
        case 'com.prepear.android.annual59':
        case 'com.prepear.android.annual79':
        case 'com.prepear.android.annual119':
            return  false;
        default:
            return false;
    }
}
