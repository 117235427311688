import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';
import { FormInput } from '@shk/prepear-controls/controls/form';

import subGiftAction from '../actions/sub-gift-action';

export class AdminSubGiftGold extends PureComponent {
    state = {
        email: '',
        trialDays: '365',
        working: false,
    }

    _onChangeState = (prop) => (e) => {
        this.setState({
            [prop]: e.target.value,
        });
    }

    _onClickGo = () => {
        this.setState({
            working: true,
        }, () => {
            const { email, trialDays } = this.state;

            this.props.subGift(email, parseInt(trialDays, 10) || 0)
                .then(() => {
                    alert('DONE');
                });
        });
    }

    render() {
        const { email, trialDays, working } = this.state;

        return (
            <div className="admin" style={{width:480}}>
                <h2>Admin Sub Gift Gold</h2>
                <FormInput
                    label={text('AdminSubGiftGold.email', 'Email')}
                    value={email}
                    onChange={this._onChangeState('email')}
                />
                <FormInput
                    label={text('AdminSubGiftGold.trialDays', 'Num of days of Free Gold')}
                    value={trialDays}
                    onChange={this._onChangeState('trialDays')}
                />
                <div style={{textAlign:'right'}}>
                    <button type="button" className="btn btn-primary" disabled={working} onClick={this._onClickGo}>{text('AdminSubGiftGold.go', 'Apply')}</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        subGift: (email, trialDays) => {
            return dispatch(subGiftAction(email, trialDays));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSubGiftGold);
