import '@shk/prepear-controls/css/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

// Import intl first so messages are configured
import {} from './intl';
import createStore from './store';
import Wrap from './components/wrap/Wrap';
import HomeMain from './routes/home/HomeMain';
import IngredientMain from './routes/ingredients/IngredientMain';
import ShoppingMain from './routes/ingredients/ShoppingMain';
import RecipeMain from './routes/recipes/RecipeMain';
import AdminMain from './routes/admin/AdminMain';
import AdminCreatePro from './routes/admin/routes/AdminCreatePro';
import AdminSubGiftGold from './routes/admin/routes/AdminSubGiftGold';
import UserSupportMain from './routes/user-support/UserSupportMain';
import CustomerInfoMain from './routes/user-support/components/CustomerInfoMain'
import './assets/css/orchardGlobal.css';

const store = createStore(window.__initialState);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/orchard">
            <Wrap>

                <Route exact path="/" render={() => (
                    <Redirect to="/home" />
                )} />
                <Route path="/home" component={HomeMain} exact={true} />
                <Route path="/ingredients">
                    <Switch>
                        <Route path="/ingredients/queue/:queueId?/:ingredientId?" component={IngredientMain} />
                        <Route path="/ingredients/queue/:queueId?" component={IngredientMain} />
                        <Route path="/ingredients/:ingredientId?" component={IngredientMain} />
                        <Route component={IngredientMain} />
                    </Switch>
                </Route>
                <Route path="/shopping">
                    <Switch>
                        <Route path="/shopping/queue/:queueId?" component={ShoppingMain} />
                        <Route path="/shopping/:ingredientId?" component={ShoppingMain} />
                        <Route component={ShoppingMain} />
                    </Switch>
                </Route>
                {/* <Route path="/recipes" component={RecipeMain} exact={true} />
                 */}
                <Route path="/user-support">
                    <Switch>
                        <Route path="/user-support/customer/:customerId?" component={CustomerInfoMain} />
                        <Route component={UserSupportMain} />
                    </Switch>
                </Route>
                <Route path="/admin">
                    <Switch>
                        <Route path="/admin/sub-gift-gold" component={AdminSubGiftGold} />
                        <Route path="/admin/create-pro" component={AdminCreatePro} />
                        <Route component={AdminMain} />
                    </Switch>
                </Route>
            </Wrap>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
