import React, { PureComponent } from 'react';

export default class HomeMain extends PureComponent {
    render() {
        return (
            <div>
                <h2>Home</h2>
            </div>
        );
    }
}
