import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';

import Ic from '@shk/prepear-controls/controls/icons';
import IngredientEditInfo from './IngredientEditInfo';
import IngredientEditLanguage from './IngredientEditLanguage';

import { NUTRIENTIDS } from '../actions/load-ingredient-action';
import saveIngredientAction from '../actions/save-ingredient-action';

import './IngredientEdit.css';

const SECTION_INFO = 'info';
const SECTION_LANG = 'lang';

export class IngredientEdit extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeSection: SECTION_INFO,
        };
    }

    componentDidMount() {
        this.setState({
            ingredient: this.props.ingredient,
            activeSection: SECTION_LANG
        });
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.ingredient !== nextProps.ingredient) {
            this.setState({
                ingredient: nextProps.ingredient,
            });
        }
    }

    _isSaveDisabled = (ingredient, existingIngredient, saving) => {
        if(!ingredient || existingIngredient === ingredient || saving) {
            return true;
        }

        const isNutrientsValid = !!(parseInt(ingredient.nutrient_grams, 10));
        const isMeasurementsValid = ingredient.measurements.reduce((isValid, measurement) => {
            return isValid && !!(parseInt(measurement.amount, 10) && parseFloat(measurement.grams));
        }, true);
        const isAKAValid = ingredient.akas.reduce((isValid, aka) => {
            return isValid && !!aka.value;
        }, true);
        const isMisSpellingValid = ingredient.mis_spellings.reduce((isValid, misspelling) => {
            return isValid && !!misspelling.value;
        }, true);

        if(!ingredient.name || !isNutrientsValid || !isMeasurementsValid || !isAKAValid || !isMisSpellingValid) {
            return true;
        }
    }

    _onClickSection = (section) => () => {
        this.setState({ activeSection: section });
    }

    _onChangeIngredient = (prop, defaultValue) => (eventOrValue) => {
        var newValue;

        if(defaultValue !== undefined) {
            newValue = defaultValue;
        } else if(eventOrValue && eventOrValue.target) {
            newValue = eventOrValue.target.value;
        } else {
            newValue = eventOrValue;
        }

        const newIngredient = { ...this.state.ingredient };

        if(prop === 'category') {
            newIngredient.category = newValue;
            newIngredient.sub_categories = null;
        } else if(prop === 'nutrient_grams') {
            newIngredient.nutrient_grams = newValue;
            newIngredient.nutrients = {};
            NUTRIENTIDS.forEach(id => newIngredient.nutrients[id] = (newIngredient.nutrients[id] || '').toString());
        } else if(Array.isArray(prop)) {
            prop.forEach((prop, idx) => newIngredient[prop] = eventOrValue[idx]);
        } else {
            newIngredient[prop] = newValue;
        }

        this.setState({
            ingredient: newIngredient
        });
    }

    _onClickSave = () => {
        this.setState({ saving: true });

        this.props.saveIngredient(this.state.ingredient)
            .then(
                (action) => {
                    this.setState({
                        saving: false
                    });

                    this.props.onIngredientUpdated(action.ingredient);
                }
            );
    }

    _onClickDuplicate = () => {
        this.setState({
            ingredient: {
                ...this.state.ingredient,
                id: undefined,
                name: '',
                akas: [],
                mis_spellings: [],
            },
            activeSection: SECTION_LANG
        });
    }

    render() {
        const { onClose, ingredient: existingIngredient, closeable } = this.props;
        const { ingredient, activeSection, saving } = this.state;

        const ingredientAllVerified = ingredient && ingredient.name && ingredient.category && ingredient.shopping_category;
        const savingDisabled = this._isSaveDisabled(ingredient, existingIngredient, saving);
        // const hasChanges = !ingredient || existingIngredient === ingredient || saving;

        return (
            <div className="ingredient-edit">
                <div className="ingredient-edit-scroll">
                    {!!ingredient && <div className="ingredient-edit-header">
                        <h2>
                            <span>{ingredient.name}</span>
                            {ingredientAllVerified ? <Ic.Verified /> : <Ic.Question />}
                        </h2>
                        <div className="ingredient-edit-header-actions">
                            <button className={activeSection === SECTION_LANG ? 'active' : ''} onClick={this._onClickSection(SECTION_LANG)}>{text('IngredientEdit.headerActionLanguage', 'Language')}</button>
                            <button className={activeSection === SECTION_INFO ? 'active' : ''} onClick={this._onClickSection(SECTION_INFO)}>{text('IngredientEdit.headerActionInfo', 'Info')}</button>
                        </div>
                        {closeable && <button onClick={onClose}>&times;</button>}
                    </div>}
                    <div className="ingredient-edit-content">
                        {!!ingredient && activeSection === SECTION_INFO && <IngredientEditInfo ingredient={ingredient} onChange={this._onChangeIngredient} />}
                        {!!ingredient && activeSection === SECTION_LANG && <IngredientEditLanguage ingredient={ingredient} onChange={this._onChangeIngredient} />}
                    </div>
                </div>
                <div className="ingredient-edit-actions">
                    <div className="ingredient-edit-actions-wrap">
                        {!!(ingredient && ingredient.id) && <button className="btn btn-secondary" onClick={this._onClickDuplicate}>{text('IngredientEditInfo.duplicate', 'Create Copy')}</button>}
                        {ingredient && <button className="btn btn-primary" disabled={savingDisabled} onClick={this._onClickSave}>{ingredient.id ? text('IngredientEditInfo.update', 'Update') : text('IngredientEditInfo.create', 'Create')}</button>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ingredient: state.ingredient.details,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveIngredient: function(ingredient) {
            return dispatch(saveIngredientAction(ingredient));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientEdit);
