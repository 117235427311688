import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import loadIngredientAction, { unloadIngredientAction } from './actions/load-ingredient-action';
import createIngredientAction from './actions/create-ingredient-action';

import IngredientQueueAuto from './components/IngredientQueueAuto';
import IngredientQueueManual from './components/IngredientQueueManual';
import IngredientSearch from './components/IngredientSearch';
import IngredientEdit from './components/IngredientEdit';

import './IngredientMain.css';

export class IngredientMain extends PureComponent {
    state = {
        editing: null,
        queue: null,
        quickAlias: null,
    }

    componentWillMount() {
        this._selectQueue(this.props);
        this._selectIngredient(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const currentParams = this.props.match.params;
        const nextParams = nextProps.match.params;
        const queueIdChanged = currentParams.queueId !== nextParams.queueId;
        const ingredientIdChanged = currentParams.ingredientId !== nextParams.ingredientId;

        if(queueIdChanged || this.props.queues !== nextProps.queues) {
            this._selectQueue(nextProps);
        }

        if (ingredientIdChanged || this.props.ingredient !== nextProps.ingredient) {
            this._selectIngredient(nextProps);
        }
    }

    _selectQueue({ match, queues }) {
        const { queueId } = match.params;

        this.setState({
            queue: queueId ? queues.find(x => x.id === queueId) : null
        });
    }

    _selectIngredient({ match, ingredient, ingredientLoading }) {
        const { ingredientId } = match.params;

        if (ingredientId) {
            if (!ingredientLoading && (!ingredient || ingredient.id !== ingredientId)) {
                if(ingredientId === 'NEW') {
                    this.props.createIngredient();
                } else {
                    this.props.loadIngredient(ingredientId);
                }
            } else if(!this.state.editing || this.state.editing.id !== ingredientId) {
                this.setState({
                    editing: ingredient,
                });
            }
        } else if(this.state.editing) {
            this.props.unloadIngredient();
            this.setState({ editing: null });
        }
    }

    _onEditIngredient = (ingredientId) => {
        const {queueId} = this.props.match.params;

        if (queueId) {
            this.props.history.push(`/ingredients/queue/${queueId}/${ingredientId}`);
        } else {
            this.props.history.push(`/ingredients/${ingredientId}`);
        }
    }

    _onCloseEditing = () => {
        const {queueId} = this.props.match.params;

        if (queueId) {
            this.props.history.push(`/ingredients/queue/${queueId}`);
        } else {
            this.props.history.push('/ingredients');
        }
    }

    _onIngredientUpdated = () => {
        if(this.queue) {
            this.queue.refreshValidation();
        }
    }

    _onChangeQuickAlias = quickAlias => {
        this.setState({ quickAlias });
    }

    render() {
        const { editing, queue, quickAlias } = this.state;

        const queueId = queue && queue.id;
        const isAutoQueue = queue && (queue.id.startsWith('ingredient-'));

        return (
            <div className="ingredients">
                {!!queue && isAutoQueue && <IngredientQueueAuto
                    ref={node => this.queue = node}
                    queue={queue}
                    selected={editing}
                    onChangeQuickAlias={this._onChangeQuickAlias}
                />}
                {!!queue && !isAutoQueue && <IngredientQueueManual
                    ref={node => this.queue = node}
                    queue={queue}
                    selected={editing}
                    onEdit={this._onEditIngredient}
                />}
                {(!queue || isAutoQueue) && <IngredientSearch
                    queueId={queueId}
                    quickAlias={quickAlias}
                    onEditIngredient={this._onEditIngredient}
                    onIngredientUpdated={this._onIngredientUpdated}
                />}
                {!!editing && <IngredientEdit
                    ingredientId={editing.id}
                    onClose={this._onCloseEditing}
                    onIngredientUpdated={this._onIngredientUpdated}
                    closeable={!queue || isAutoQueue}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        queues: state.queue.entities.ingredient,
        ingredient: state.ingredient.details,
        ingredientLoading: state.ingredient.detailsLoading,
    }
}

function mapDispatchToProps(disatch) {
    return {
        createIngredient: function() {
            return disatch(createIngredientAction());
        },
        loadIngredient: function(ingredientId) {
            return disatch(loadIngredientAction(ingredientId));
        },
        unloadIngredient: function() {
            return disatch(unloadIngredientAction());
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IngredientMain);
