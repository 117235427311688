import axios from 'axios';

export const ESCALATE_INGRED_ITEM_REQUEST = 'ESCALATE_INGRED_ITEM_REQUEST';
export const ESCALATE_INGRED_ITEM_SUCCESS = 'ESCALATE_INGRED_ITEM_SUCCESS';
export const ESCALATE_INGRED_ITEM_FAILURE = 'ESCALATE_INGRED_ITEM_FAILURE';

export default (queueId, queueItemId, note) => (dispatch) => {
    dispatch({ type: ESCALATE_INGRED_ITEM_REQUEST });

    return axios.post(`/api/v1/orchard/queue/${queueId}/${queueItemId}/escalate`, {
        note
    })
        .then(
            () => {
                return dispatch({
                    type: ESCALATE_INGRED_ITEM_SUCCESS,
                    queueId,
                    queueItemId,
                });
            },
            (err) => {
                return Promise.reject(dispatch({
                    type: ESCALATE_INGRED_ITEM_FAILURE,
                    err
                }));
            }
        )
}
