import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './AdminMain.css';

export class AdminMain extends PureComponent {
    state = {
    }

    render() {
        return (
            <div className="admin">
                <h2>Admin operations</h2>

                <div className="card">
                    <div className="card-header">Subscriptions</div>
                    <div className="card-body">
                        <Link to="/admin/sub-gift-gold" className="btn btn-primary">Gift Prepear Gold to user</Link>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">Pro's</div>
                    <div className="card-body">
                        <Link to="/admin/create-pro" className="btn btn-primary">Create Pro user</Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(AdminMain);
