import { NUTRIENTIDS } from './load-ingredient-action';

export const CREATE_INGREDIENT_SUCCESS = 'CREATE_INGREDIENT_SUCCESS';

export default () => {
    const nutrients = {};

    NUTRIENTIDS.forEach(id => nutrients[id] = '');

    return {
        type: CREATE_INGREDIENT_SUCCESS,
        ingredient: {
            id: 'NEW',
            name: '',
            allergies: [],
            category: '',
            classifications: [],
            seasons: [],
            sub_categories: [],
            standard_amount: '',
            standard_measurement: '',
            standard_grams: '',
            measurements: [],
            akas: [],
            aka_name_ids: [],
            mis_spellings: [],
            mis_spelling_name_ids: [],
            nutrients,
            nutrient_grams: '100',
        }
    };
}
