import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { text } from '@shk/react-simple-intl';

import { FormInput, MultiSelect, CheckboxList } from '@shk/prepear-controls/controls/form';
import Ic from '@shk/prepear-controls/controls/icons';

import typeaheadIngredientsAction from '../actions/typeahead-ingredients-action';
import { NUTRIENTID_CALORIES, NUTRIENTID_FAT, NUTRIENTID_SATURATEDFAT, NUTRIENTID_TRANSFAT, NUTRIENTID_CHOLESTEROL, NUTRIENTID_SODIUM, NUTRIENTID_CARBS, NUTRIENTID_FIBER, NUTRIENTID_SUGARS, NUTRIENTID_PROTEIN } from '../actions/load-ingredient-action';
import ingredientCategories from '../data/ingredient-categories.json';
import allergies from '../data/ingredient-allergies.json';
import seasons from '../data/ingredient-seasons.json';
import classifications from '../data/ingredient-classifications.json';
import shoppingCategories from '../data/ingredient-shopping.json';
import measurements from '../data/ingredient-measurements.json';

import './IngredientEditInfo.css';

const SECTION_CATEGORY = 'info_category';
const SECTION_SHOPPING = 'info_shopping';
const SECTION_MEASUREMENTS = 'info_measurements';
const SECTION_NUTRITION = 'info_nutrition';

export class IngredientEditInfo extends PureComponent {
    state = {
        activeSection: SECTION_CATEGORY,
    }

    componentWillMount() {
        const { ingredient, onChange } = this.props;

        this._changeMeasurement = onChange('measurements');
        this._changeNutrients = onChange('nutrients');

        this._loadSubCats(ingredient);
    }

    componentWillReceiveProps(nextProps) {
        const { ingredient } = this.props;

        if(ingredient.category !== nextProps.ingredient.category) {
            this._loadSubCats(nextProps.ingredient);
        }
    }

    _loadSubCats = ({ category }) => {
        const ingredientCat = ingredientCategories.find(x => x.value === category);

        this.setState({
            subCategories: (ingredientCat && ingredientCat.sub) || [],
        });
    }

    _onSearchOptionsSubs = (search, callback) => {
        this.pendingSearchSubsValue = search;

        if(!this.pendingSearchSubs) {
            this.pendingSearchSubs = setTimeout(() => {
                this.pendingSearchSubs = null;

                this.props.typeahead(this.pendingSearchSubsValue)
                    .then(
                        (action) => callback(action.data.ingredients.map(ing => ({
                            value: ing.id,
                            text: ing.name
                        })))
                    );
            }, 300);
        }
    }

    _onChangeMeasurement = (prop, idx) => (e) => {
        const newMeasurements = this.props.ingredient.measurements.slice();

        newMeasurements[idx] = {
            ...newMeasurements[idx],
            [prop]: e.target.value,
        };

        this._changeMeasurement(newMeasurements);
    }

    _onClickAddMeasurement = () => {
        const { measurements } = this.props.ingredient;
        const newMeasurements = measurements.concat([{
            $id: measurements.reduce((res, cur) => cur.$id + 1, 1),
            amount: '',
            name: '',
            grams: '',
        }]);

        this._changeMeasurement(newMeasurements);
    }

    _onClickRemoveMeasurement = (idx) => () => {
        const newMeasurements = this.props.ingredient.measurements.slice();

        newMeasurements.splice(idx, 1);

        this._changeMeasurement(newMeasurements);
    }

    _onChangeNutrient = (prop) => (e) => {
        const newNutrients = { ...this.props.ingredient.nutrients };

        newNutrients[prop] = e.target.value;

        this._changeNutrients(newNutrients);
    }

    render() {
        const { ingredient, onChange } = this.props;
        const { /*activeSection, */subCategories } = this.state;

        return (
            <div className="ingredient-edit-info">
                {/* <ul className="ingredient-edit-info-nav cf">
                    <li
                        className={activeSection === SECTION_CATEGORY ? 'active' : ''}
                        onClick={this._onClickSection(SECTION_CATEGORY)}>
                        {text('IngredientEditInfo.navCategory', 'Category')}
                    </li>
                    <li
                        className={activeSection === SECTION_SHOPPING ? 'active' : ''}
                        onClick={this._onClickSection(SECTION_SHOPPING)}>
                        {text('IngredientEditInfo.navShopping', 'Shopping')}
                    </li>
                    <li
                        className={activeSection === SECTION_MEASUREMENTS ? 'active' : ''}
                        onClick={this._onClickSection(SECTION_MEASUREMENTS)}>
                        {text('IngredientEditInfo.navMeasurements', 'Measurements')}
                    </li>
                    <li
                        className={activeSection === SECTION_NUTRITION ? 'active' : ''}
                        onClick={this._onClickSection(SECTION_NUTRITION)}>
                        {text('IngredientEditInfo.navNutrition', 'Nutrition')}
                    </li>
                </ul> */}
                <section ref={node => this[SECTION_CATEGORY] = node}>
                    <h2>{text('IngredientEditInfo.sectionIngredientCategory', 'Ingredient Category')}</h2>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                type="select"
                                label={text('IngredientEditInfo.primaryCategory', 'Primary Category')}
                                options={ingredientCategories}
                                value={ingredient.category || ''}
                                onChange={onChange('category')}
                            />
                        </div>
                        {!!subCategories.length && <div className="col-6">
                            <MultiSelect
                                label={text('IngredientEditInfo.secondaryCategory', 'Secondary Category')}
                                values={ingredient.sub_categories || []}
                                onChange={onChange('sub_categories')}
                                options={subCategories}
                            />
                        </div>}
                    </div>
                    <MultiSelect
                        label={text('IngredientEditInfo.allergies', 'Allergy Concerns')}
                        values={ingredient.allergies || []}
                        onChange={onChange('allergies')}
                        options={allergies}
                    />
                    <div className="row">
                        <div className="col-6">
                            <h5>{text('IngredientEditInfo.ingredientSubs', 'Possible Ingredient Substitutions')}</h5>
                            <MultiSelect
                                values={ingredient.substitutions || []}
                                onChange={onChange('substitutions')}
                                onSearchOptions={this._onSearchOptionsSubs}
                            />
                        </div>
                        <div className="col-3">
                            <CheckboxList
                                label={text('IngredientEditInfo.seasons', 'Seasons')}
                                values={ingredient.seasons || []}
                                onChange={onChange('seasons')}
                                options={seasons}
                            />
                        </div>
                        <div className="col-3">
                            <CheckboxList
                                label={text('IngredientEditInfo.classifications', 'Classifications')}
                                values={ingredient.classifications || []}
                                onChange={onChange('classifications')}
                                options={classifications}
                            />
                        </div>
                    </div>
                </section>
                <section ref={node => this[SECTION_SHOPPING] = node}>
                    <h2>{text('IngredientEditInfo.sectionShopping', 'Shopping')}</h2>
                    <FormInput
                        type="select"
                        label={text('IngredientEditInfo.shoppingCategory', 'Default Shopping Category')}
                        options={shoppingCategories}
                        value={ingredient.shopping_category || ''}
                        onChange={onChange('shopping_category')}
                    />
                </section>
                <section ref={node => this[SECTION_MEASUREMENTS] = node}>
                    <h2>{text('IngredientEditInfo.sectionStandardMeasurement', 'Standard Measurement')}</h2>
                    <div className="row">
                        <div className="col-4">
                            <FormInput
                                label={text('IngredientEditInfo.standardAmount', 'Amount')}
                                value={ingredient.standard_amount}
                                onChange={onChange('standard_amount')}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                type="select"
                                label={text('IngredientEditInfo.standardMeasurement', 'Measurement')}
                                options={measurements}
                                value={ingredient.standard_measurement}
                                onChange={onChange('standard_measurement')}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                label={text('IngredientEditInfo.standardGrams', 'Grams')}
                                value={ingredient.standard_grams}
                                onChange={onChange('standard_grams')}
                            />
                        </div>
                    </div>
                    <h2>{text('IngredientEditInfo.sectionMeasurement', 'Non-Standard Measurements')}</h2>
                    <p>{text('IngredientEditInfo.sectionMeasurementInfo', 'What custom measurements are applicable for this ingredient? These are measurements that are unique to this ingredient. e.g. leaves, quarter, large, medium. ')}</p>
                    {ingredient.measurements.map((measurement, measurementIdx) => (
                        <div key={measurement.$id} className="row">
                            <div className="col-4">
                                <FormInput
                                    label={text('IngredientEditInfo.customAmount', 'Amount')}
                                    value={measurement.amount}
                                    onChange={this._onChangeMeasurement('amount', measurementIdx)}
                                />
                            </div>
                            <div className="col-4">
                                <FormInput
                                    label={text('IngredientEditInfo.customerMeasurement', 'Measurement')}
                                    value={measurement.name}
                                    onChange={this._onChangeMeasurement('name', measurementIdx)}
                                />
                            </div>
                            <div className="col-4 with-trash">
                                <FormInput
                                    label={text('IngredientEditInfo.customerGrams', 'Grams')}
                                    value={measurement.grams}
                                    onChange={this._onChangeMeasurement('grams', measurementIdx)}
                                />
                                <button onClick={this._onClickRemoveMeasurement(measurementIdx)}><Ic.Trash/></button>
                            </div>
                        </div>
                    ))}
                    <button className="btn btn-secondary" onClick={this._onClickAddMeasurement}>
                        <Ic.SimplePlus />
                        <span>{text('IngredientEditInfo.addMeasurement', 'Add a Non-Standard Measurement')}</span>
                    </button>
                </section>
                <section ref={node => this[SECTION_NUTRITION] = node} className="ingredient-edit-info-nutrient">
                    <h2 className="ingredient-edit-info-nutrient-header">
                        <span>{text('IngredientEditInfo.nutrientsSection', 'Nutrients per')}</span>
                        <FormInput
                            value={ingredient.nutrient_grams}
                            onChange={onChange('nutrient_grams')}
                        />
                        <span>{text('IngredientEditInfo.nutrientsSectionGrams', 'Grams')}</span>
                    </h2>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.calories', 'Calories')}
                                value={ingredient.nutrients[NUTRIENTID_CALORIES]}
                                onChange={this._onChangeNutrient(NUTRIENTID_CALORIES)}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.Sodium', 'Sodium (mg)')}
                                value={ingredient.nutrients[NUTRIENTID_SODIUM]}
                                onChange={this._onChangeNutrient(NUTRIENTID_SODIUM)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.totalFat', 'Total Fat (g)')}
                                value={ingredient.nutrients[NUTRIENTID_FAT]}
                                onChange={this._onChangeNutrient(NUTRIENTID_FAT)}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.totalCarbs', 'Total Carbohydrate (g)')}
                                value={ingredient.nutrients[NUTRIENTID_CARBS]}
                                onChange={this._onChangeNutrient(NUTRIENTID_CARBS)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 offset-1">
                            <FormInput
                                label={text('IngredientEditInfo.saturatedFat', 'Saturated Fat (g)')}
                                value={ingredient.nutrients[NUTRIENTID_SATURATEDFAT]}
                                onChange={this._onChangeNutrient(NUTRIENTID_SATURATEDFAT)}
                            />
                        </div>
                        <div className="col-5 offset-1">
                            <FormInput
                                label={text('IngredientEditInfo.fiber', 'Dietary Fiber (g)')}
                                value={ingredient.nutrients[NUTRIENTID_FIBER]}
                                onChange={this._onChangeNutrient(NUTRIENTID_FIBER)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 offset-1">
                            <FormInput
                                label={text('IngredientEditInfo.transFat', 'Trans Fat (g)')}
                                value={ingredient.nutrients[NUTRIENTID_TRANSFAT]}
                                onChange={this._onChangeNutrient(NUTRIENTID_TRANSFAT)}
                            />
                        </div>
                        <div className="col-5 offset-1">
                            <FormInput
                                label={text('IngredientEditInfo.sugars', 'Sugars (g)')}
                                value={ingredient.nutrients[NUTRIENTID_SUGARS]}
                                onChange={this._onChangeNutrient(NUTRIENTID_SUGARS)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.cholesterol', 'Cholesterol (mg)')}
                                value={ingredient.nutrients[NUTRIENTID_CHOLESTEROL]}
                                onChange={this._onChangeNutrient(NUTRIENTID_CHOLESTEROL)}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                label={text('IngredientEditInfo.protein', 'Protein (g)')}
                                value={ingredient.nutrients[NUTRIENTID_PROTEIN]}
                                onChange={this._onChangeNutrient(NUTRIENTID_PROTEIN)}
                            />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        typeahead: (search) => {
            return dispatch(typeaheadIngredientsAction(search));
        }
    };
}

export default connect(null, mapDispatchToProps)(IngredientEditInfo);
