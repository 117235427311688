import React, { PureComponent } from 'react';
import './Modal.css';
// none
export default class Modal extends PureComponent {
    render() {
        const { children, onClose, Open } = this.props;

        return (
            Open ? <div className='modal'>
                <div className="modal-content">
                    <div onClick={onClose} className='modal-close'>&#10005;</div>

                    {children}
                </div>
                <div onClick={onClose} className="modal-background"></div>

            </div>
            : null
        )
    }
}


